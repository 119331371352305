import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import PrintIcon from '@mui/icons-material/Print'
import SearchIcon from '@mui/icons-material/Search'
import { Checkbox, FormControlLabel, Paper, Popover } from '@mui/material'
import axios from 'axios'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { components } from 'react-select'
import { AsyncPaginate } from 'react-select-async-paginate'
import { toast } from 'react-toastify'
import {
  clinicApiURL,
  commonHeadersCPWeb,
  getCPDataWithoutParams,
  postCPDataWithoutParams,
  putCPDataWithoutParams,
} from 'src/clinic/services'
import {
  APP_CONSTANTS,
  BLANK_PATIENT_DATA,
  BOOKING_REQUEST_STATUS,
  DAY_CALENDAR_EACH_SLOT_HEIGHT_PX,
  DAY_CALENDAR_EACH_SLOT_TWO_ROW_HEIGHT_PX,
  FULL_DAY_EVERYDAY_SCHEDULE,
  GENDER_LIST_OFFLINE,
  MOMENT_FORMATS,
  OFFLINE_PAYMENT_METHODS_LIST,
  PATIENT_VALIDATION_FIELDS,
  RELATIONSHIP_OPTIONS,
  STATUS_SESSION_CANCELLED,
  SUBTYPE_POST_CONSULTATION,
  SUBTYPE_PRE_CONSULTATION,
  allAvailableEventTypes,
  currency_list,
  platformOptions,
} from 'src/consumer/constants'
import { getAgeFromDobWithTime, getDOBWithTime } from 'src/consumer/helpers/profilePageHelper'
import {
  areStringsEqualIgnoreCase,
  removePlusFromBeginning,
  validateEmail,
  validateName,
  validateOnlyNumbers,
} from 'src/consumer/helpers/utilFunctions'
import { platformWeb } from 'src/consumer/services'
import {
  NewEventTypeButton,
  StyledEventsRefreshIcon,
} from 'src/mentor/components/MentorEventsPage/MentorEventsPageStyles'
import {
  getDefaultScheduleFromSchedules,
  getSubscriptionPlanById,
  isCommonResponseSuccessful,
  markBookedSessionAsCompletedFunction,
} from 'src/mentor/helpers/utilityFunctions'
import { GenericDisplayFlex, GenericDisplayFlexColumn } from 'src/mentor/layout/Styles'
import { v4 as uuidv4 } from 'uuid'
import {
  getAvailabilityForDayCalendar,
  getCorrectScreeningReasonMessageObject as getScreeningReasonMessageObject,
  getSlotGroupsForDayCalendar,
} from './ClinicManageBookingsHelper'
import {
  BookingRequestContainer,
  BookingRequestItemsParent,
  CashDenominationInput,
  CashDenominationText,
  EDVCBookNewSlotSelect,
  EDVCBookedSlotDetailsContainer,
  EDVCButton,
  EDVCContainer,
  EDVCDateContainer,
  EDVCEventsContainer,
  EDVCExtraSlotItemForTime,
  EDVCNewBookingContainer,
  EDVCParent,
  EDVCPhoneInput,
  EDVCPresentTimeLine,
  EDVCPresentTimeLineDot,
  EDVCRectangleButton,
  EDVCSlotButton,
  EDVCSlotColoredItem,
  EDVCSlotItem,
  EDVCSlotItemContainer,
  EDVCSlotItemTime,
  EDVCSlotStartTime,
  PIParent,
  PISection,
  PISectionInput,
  PISectionItem,
  PISectionTitle,
  PISectionValidation,
  RRBContainer,
  RRBItem,
  SearchSectionParent,
} from './ClinicManageBookingsPageStyles'

import AddIcon from '@mui/icons-material/Add'
import ChairIcon from '@mui/icons-material/Chair'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import InfoIcon from '@mui/icons-material/Info'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import SummarizeIcon from '@mui/icons-material/Summarize'
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront'
import { Button as MuiButton } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import dayjs from 'dayjs'
import Calendar from 'react-calendar'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { GenericEventBookingComponent } from 'src/consumer/components/EventBooking/BookingComponents'
import { CustomHTMLSelect, GenericLoadingOverlay } from 'src/consumer/components/helperComponents'
import {
  cancelBookingRequestFuncForAssistant,
  sendDaySummaryToDoctor,
} from 'src/consumer/services/featureServices'
import { FCRCalendarContainer } from 'src/mentor/components/FeeCollectionReport/FeeCollectionReportStyles'
import { ConfirmCancelSessionDialog } from 'src/mentor/components/MentorEventsPage/MentorEventsComponentsAndHelpers'
import './LoaderComponent.css'
import { CompositeBookingRequestCreation } from './TherapyRequestBookingComponents'
import ScreeningCheckUI from './screening/ScreeningCheckUI'
import NewBookingDialogReasonForNotDoingScreeningForm from './newBookingDialog/NewBookingDialogReasonForNotDoingScreeningForm'
import { Error } from '@mui/icons-material'

const utcPlugin = require('dayjs/plugin/utc')
dayjs.extend(utcPlugin)

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

const BootStrapDialogPaperComponent = styled(Paper)(
  ({ theme }) => `
    max-width: unset !important;
    @media (max-height: 980px) {
      position: absolute !important;
      top: 16px;
    }
    `,
)

// main stuff :

export function DayViewCalendarComponent({
  forMentor = false,
  mentorId,
  mentorProfileData,
  mentorClinicAssociationData,
  allProfessionalsDataForAssistant,
  thisClinicData,
  bookingRequestsData,
  bookingRequestsDataLoading,
  setFetchDataTriggerForBookingRequests,
  canPersonPrintPrescription,
  chambersData,
  assistantProfileInThisClinic,
}) {
  const navigate = useNavigate()
  const presentTimeLineRef = useRef(null)
  const [eventTypesVisible, setEventTypesVisible] = useState([])
  const [selectedEventTypeId, setSelectedEventTypeId] = useState(
    mentorProfileData?.eventTypes?.[0]?.uid,
  )
  const [selectedDate, setSelectedDate] = useState(moment().format('DD-MM-YYYY'))
  const timeZone = encodeURIComponent(moment().format('Z'))
  const [pendingActionsOfPerson, setPendingActionsOfPerson] = useState()
  const [bookedSlotsInDay, setBookedSlotsInDay] = useState([])
  const [bookedSlotsInDayLoading, setBookedSlotsInDayLoading] = useState(false)
  const [heightOfEachSlot, setHeightOfEachSlot] = useState(DAY_CALENDAR_EACH_SLOT_HEIGHT_PX)
  const [planDataForSelectedEventId, setPlanDataForSelectedEventId] = useState()
  const [showCancelledOnly, setShowCancelledOnly] = useState(false)
  const [cancelledCount, setCancelledCount] = useState(0)
  const [cancelDialogData, setCancelDialogData] = useState(null)

  const [sendReportLoading, setSendReportLoading] = useState(false)

  const [selectedClinicBrandData, setSelectedClinicBrandData] = useState()

  const eventDataForSelectedEventType = mentorProfileData?.eventTypes?.find(
    (eventType) => eventType?.uid === selectedEventTypeId,
  )
  const gapDurationInMins = eventDataForSelectedEventType?.durationInMins || 1
  const selectedClinic = thisClinicData

  const scheduleForSelectedEventType = useMemo(() => {
    if (
      eventDataForSelectedEventType?.contextId === selectedClinic?.id &&
      Array.isArray(mentorClinicAssociationData) &&
      mentorClinicAssociationData?.length > 0
    ) {
      if (
        mentorProfileData?.schedules?.some((schedule) =>
          schedule?.scopeSchedules?.includes(selectedClinic?.id),
        )
      ) {
        const arrayOfAllSchedulesAssoicatedWithThisClinic = mentorProfileData?.schedules?.filter(
          (schedule) => schedule?.scopeSchedules?.includes(selectedClinic?.id),
        )
        const clinicAssociatedScheduleWithThisEventId =
          arrayOfAllSchedulesAssoicatedWithThisClinic?.find((schedule) =>
            schedule?.eventIds?.includes(selectedClinic?.id),
          )
        if (clinicAssociatedScheduleWithThisEventId) {
          return clinicAssociatedScheduleWithThisEventId
        } else if (arrayOfAllSchedulesAssoicatedWithThisClinic?.length > 0) {
          return arrayOfAllSchedulesAssoicatedWithThisClinic?.[0]
        }
      } else {
        const defaultClinicSchedule = getDefaultScheduleFromSchedules(selectedClinic?.schedules)
        if (defaultClinicSchedule) {
          return defaultClinicSchedule
        }
      }
    } else {
      const scheduleWithRequestedEventId = mentorProfileData?.schedules?.find((schedule) =>
        schedule?.eventIds?.includes(selectedEventTypeId),
      )
      if (scheduleWithRequestedEventId) {
        return scheduleWithRequestedEventId
      }
    }
  }, [
    eventDataForSelectedEventType?.contextId,
    selectedClinic?.id,
    selectedClinic?.schedules,
    mentorClinicAssociationData,
    mentorProfileData?.schedules,
    selectedEventTypeId,
  ])

  const availabilityOfDay = getAvailabilityForDayCalendar(selectedDate, timeZone, {
    timeZone: timeZone,
    ...(true ? FULL_DAY_EVERYDAY_SCHEDULE : scheduleForSelectedEventType),
    // eventDataForSelectedEventType?.type === allAvailableEventTypes.screening
  })

  console.log(availabilityOfDay)

  const slotGroups = getSlotGroupsForDayCalendar(
    availabilityOfDay,
    gapDurationInMins,
    timeZone,
    bookedSlotsInDay,
  )
  const selectedDateStartTime = moment(selectedDate, 'DD-MM-YYYY')?.format(
    `YYYY-MM-DDTHH:mm:ss[${decodeURIComponent(timeZone)}]`,
  )
  const selectedDateEndTime = moment(selectedDate, 'DD-MM-YYYY')
    ?.endOf('day')
    ?.format(`YYYY-MM-DDTHH:mm:ss[${decodeURIComponent(timeZone)}]`)
  const selectedDateUtcStartTime = moment
    ?.utc(selectedDateStartTime)
    ?.format(`YYYY-MM-DDTHH:mm:ss[Z]`)
  const selectedDateUtcEndTime = moment?.utc(selectedDateEndTime)?.format(`YYYY-MM-DDTHH:mm:ss[Z]`)

  const [clickedBookedSlotAnchor, setClickedBookedSlotAnchor] = useState(null)
  const [clickedBookedSlotData, setClickedBookedSlotData] = useState({})

  const [clickedWantedSlotData, setClickedWantedSlotData] = useState({})
  const [clickedWantedSlotAppointmentNumber, setClickedWantedSlotAppointmentNumber] = useState(0)
  const [newBookingDialogOpen, setNewBookingDialogOpen] = useState(false)

  const [editPatientDialogOpen, setEditPatientDialogOpen] = useState(false)
  const [pendingActionsDialogOpen, setPendingActionsDialogOpen] = useState(false)

  const [therapyBookingDialogOpen, setTherapyBookingDialogOpen] = useState(false)
  const [dataForTherapyBookingDialog, setDataForTherapyBookingDialog] = useState(false)

  const [chooseDateCalendarVisible, setChooseDateCalendarVisible] = useState(false)

  // distinction between clinic assistant api and mentor api
  async function getAndSetBookedSlotsOfSelectedDate() {
    if (mentorId) {
      setBookedSlotsInDayLoading(true)
      let response
      if (forMentor) {
        response = await getCPDataWithoutParams(
          `/api/secure/teacher/booked-slots/all?startTime=${selectedDateUtcStartTime}&endTime=${selectedDateUtcEndTime}`,
        )
      } else {
        response = await getCPDataWithoutParams(
          `/api/secure/clinic-assistant/booked-slots/all?mentorId=${mentorId}&doctorId=${mentorId}&startTime=${selectedDateUtcStartTime}&endTime=${selectedDateUtcEndTime}`,
        )
      }
      if (!forMentor) {
        const responsePendingActions = await getCPDataWithoutParams(
          `/api/secure/patient/patient-actions/pending-on-me?clinicId=${selectedClinic?.id}`,
        )
        setPendingActionsOfPerson(responsePendingActions?.data?.data?.pendingActions)
      }
      setBookedSlotsInDayLoading(false)
      if (response?.status === 200) {
        const bookedSlotsPerUtc = response?.data?.sort(customSortForDayCalendar)
        let bookedSlotsPeruser = []
        if (Array.isArray(bookedSlotsPerUtc) && bookedSlotsPerUtc?.length > 0) {
          bookedSlotsPerUtc?.forEach((bookedSlotItem) => {
            const { minutesToAddToThisEvent } = getOverlapDataWithPreviousSlots(
              bookedSlotItem,
              bookedSlotsPerUtc,
            )
            bookedSlotsPeruser.push({
              ...bookedSlotItem,
              endTimeForDayCal: moment
                ?.utc(bookedSlotItem.endTime, 'YYYY-MM-DD HH:mm:ss')
                ?.utcOffset(decodeURIComponent(timeZone))
                ?.add(minutesToAddToThisEvent, 'seconds')
                ?.format(`YYYY-MM-DDTHH:mm:ss[${decodeURIComponent(timeZone)}]`),
              startTimeForDayCal: moment
                ?.utc(bookedSlotItem.startTime, 'YYYY-MM-DD HH:mm:ss')
                ?.utcOffset(decodeURIComponent(timeZone))
                ?.add(minutesToAddToThisEvent, 'seconds')
                ?.format(`YYYY-MM-DDTHH:mm:ss[${decodeURIComponent(timeZone)}]`),
              wasDelayedBecauseOfOverlaps: minutesToAddToThisEvent > 0 ? true : false,
            })
          })
        }
        if (shouldShowTwoRowDesign(bookedSlotsPeruser)) {
          setHeightOfEachSlot(DAY_CALENDAR_EACH_SLOT_TWO_ROW_HEIGHT_PX)
        } else {
          setHeightOfEachSlot(DAY_CALENDAR_EACH_SLOT_HEIGHT_PX)
        }

        const cancelledToday = (bookedSlotsPeruser || [])?.filter(
          (item) => item?.status === STATUS_SESSION_CANCELLED,
        )
        setCancelledCount(cancelledToday?.length)
        if (showCancelledOnly) {
          bookedSlotsPeruser = cancelledToday
        } else {
          bookedSlotsPeruser = bookedSlotsPeruser.filter(
            (item) => item?.status !== STATUS_SESSION_CANCELLED,
          )
        }
        setBookedSlotsInDay(bookedSlotsPeruser)
      }
    }
  }

  function onPrevClick() {
    if (moment().format('DD-MM-YYYY') === selectedDate) {
      setSelectedDate(moment(selectedDate, 'DD-MM-YYYY').subtract(1, 'day').format('DD-MM-YYYY'))
    } else {
      setSelectedDate(moment(selectedDate, 'DD-MM-YYYY').subtract(1, 'day').format('DD-MM-YYYY'))
    }
  }

  function onNextClick() {
    setSelectedDate(moment(selectedDate, 'DD-MM-YYYY').add(1, 'day').format('DD-MM-YYYY'))
  }

  function handleBookedSlotOpenPopover(event) {
    setClickedBookedSlotAnchor(event.currentTarget)
  }

  function getHeightFromTopInSlotGroup(timeBlock, clickedWantedSlotData, gapDurationInMins) {
    const startTimeOfTimeBlock = moment(timeBlock[0]?.startTime)
    const minutesPassedSinceStartOfTimeBlock = moment(clickedWantedSlotData?.startTime)?.diff(
      startTimeOfTimeBlock,
      'minutes',
    )
    return (minutesPassedSinceStartOfTimeBlock / gapDurationInMins) * heightOfEachSlot
  }

  function getHeightFromTopForCurrentTime(timeBlock, gapDurationInMins) {
    const startTimeOfTimeBlock = moment(timeBlock[0]?.startTime)
    const minutesPassedSinceStartOfTimeBlock = moment()?.diff(startTimeOfTimeBlock, 'minutes')
    return (minutesPassedSinceStartOfTimeBlock / gapDurationInMins) * heightOfEachSlot
  }

  const tooltipComp = (
    <Tooltip id="tooltip">
      <strong>Refresh Bookings</strong>
    </Tooltip>
  )

  useEffect(() => {
    getAndSetBookedSlotsOfSelectedDate() // Initial call
    const intervalId = setInterval(() => {
      getAndSetBookedSlotsOfSelectedDate()
    }, 75000)
    return () => {
      clearInterval(intervalId)
    }
  }, [selectedDate, mentorId, showCancelledOnly]) // call every 30 seconds

  useEffect(() => {
    ;(async () => {
      const eventDataForSelectedEventType = mentorProfileData?.eventTypes?.find(
        (eventType) => eventType?.uid === selectedEventTypeId,
      )
      if (eventDataForSelectedEventType && !eventDataForSelectedEventType?.free) {
        const response = await getSubscriptionPlanById(
          eventDataForSelectedEventType?.subscriptionPlanId,
        )
        if (response.status === 200) {
          setPlanDataForSelectedEventId(response?.data)
        }
      } else {
        setPlanDataForSelectedEventId(null)
      }
    })()
  }, [selectedEventTypeId])

  useEffect(() => {
    let arrayToInsert = []
    mentorProfileData?.eventTypes?.forEach((eventTypeItem) => {
      if (eventTypeItem?.contextId === selectedClinic?.id) {
        arrayToInsert.push(eventTypeItem)
      }
    })
    arrayToInsert = [
      ...arrayToInsert.filter((item) => item?.meetingSrc === 'offline'),
      ...arrayToInsert.filter((item) => item?.meetingSrc === 'aumhum'),
    ]
    setEventTypesVisible(arrayToInsert)
    setSelectedEventTypeId(arrayToInsert?.[0]?.uid)
  }, [mentorProfileData])

  useEffect(() => {
    if (presentTimeLineRef.current) {
      const elementRect = presentTimeLineRef.current.getBoundingClientRect()
      const scrollPosition = elementRect.top - 100
      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      })
    }
  }, [selectedEventTypeId])

  async function getAndSetClinicBrandData(clinicBrandId) {
    setSelectedClinicBrandData()
    const response = await getCPDataWithoutParams(
      `/api/external/clinic/brand-and-clinics?id=${clinicBrandId}`,
    )
    if (response?.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
      setSelectedClinicBrandData(response?.data?.data?.clinicBrand)
    }
  }

  async function sendDayReportForDoctor() {
    setSendReportLoading(true)
    const response = await sendDaySummaryToDoctor(selectedDateUtcStartTime, selectedDateUtcEndTime)
    if (response?.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
      toast.success("Day's summary sent to your preferred communication mediums.")
    } else {
      toast.error('Error sending summary to your preferred communication mediums')
    }
    setSendReportLoading(false)
  }

  useEffect(() => {
    if (
      selectedClinicBrandData &&
      selectedClinicBrandData?.clinicBrand?.id === selectedClinic?.id
    ) {
    } else {
      getAndSetClinicBrandData(selectedClinic?.clinicBrandId)
    }
  }, [selectedClinic])

  let bookedSlotActionText
  if (
    clickedBookedSlotData?.discountAction &&
    clickedBookedSlotData?.discountAction?.discountType === 'discountedPrice' &&
    clickedBookedSlotData?.discountAction?.discountedPrice
  ) {
    bookedSlotActionText = `Updated Fee: ${clickedBookedSlotData?.discountAction?.discountedPrice}  `
  } else {
    bookedSlotActionText = ''
  }

  async function handleNewTherapyRequest(therapyBookingDataForThisEvent, bookedSlot) {
    setTherapyBookingDialogOpen(true)
    setDataForTherapyBookingDialog({ therapyBookingDataForThisEvent, bookedSlot })
  }

  return (
    <>
      <EDVCParent>
        <Popover
          id="bookedSlotPopover"
          anchorEl={clickedBookedSlotAnchor}
          open={Boolean(clickedBookedSlotAnchor)}
          onClose={() => setClickedBookedSlotAnchor(null)}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <EDVCBookedSlotDetailsContainer>
            <div>
              {clickedBookedSlotData?.patientId ? 'Patient' : 'Client'}:{' '}
              {clickedBookedSlotData?.userName}
            </div>
            <div>
              {
                platformOptions?.find(
                  (platformItem) => platformItem?.id === clickedBookedSlotData?.meetingSrc,
                )?.name
              }
            </div>
            {clickedBookedSlotData?.chamberRoomName && (
              <div style={{ fontSize: '12px' }}>
                {'('}
                {clickedBookedSlotData?.chamberRoomName}
                {')'}
              </div>
            )}
            <div>
              {moment?.utc(clickedBookedSlotData?.startTime).local()?.format('h:mma')} -{' '}
              {moment?.utc(clickedBookedSlotData?.endTime).local()?.format('h:mma')}
            </div>
            {clickedBookedSlotData?.meetingSrc === 'offline' && (
              <>
                <div>
                  Fee Paid: {(clickedBookedSlotData?.currency || '')?.toUpperCase()}{' '}
                  {clickedBookedSlotData?.feePaid}
                </div>
                <div>
                  Fee Payment Mode:{' '}
                  {
                    OFFLINE_PAYMENT_METHODS_LIST.find(
                      (item) => item.id === clickedBookedSlotData?.feePaymentMode,
                    )?.title
                  }
                </div>
              </>
            )}
            {bookedSlotActionText && (
              <div>
                <u>{bookedSlotActionText}</u>
                {pendingActionsOfPerson?.find(
                  (item) =>
                    item?.type === 'refund' && item?.bookedEventId === clickedBookedSlotData?.id,
                ) ? (
                  <span style={{ fontWeight: '500', color: 'red' }}>(Action Pending)</span>
                ) : (
                  <span style={{ fontWeight: '500', color: 'green' }}>(Action Performed)</span>
                )}
              </div>
            )}
            {/* // distinction between clinic assistant api and mentor api */}
            {forMentor && (
              <>
                <EDVCButton
                  invert
                  disabled={false}
                  onClick={() => {
                    navigate(`/l/${clickedBookedSlotData?.slug}`)
                  }}
                >
                  {clickedBookedSlotData?.markedCompleted ? 'Rejoin' : 'Start'}
                </EDVCButton>
                {!Boolean(clickedBookedSlotData?.markedCompleted) && (
                  <EDVCButton
                    onClick={async () => {
                      await markBookedSessionAsCompletedFunction(clickedBookedSlotData)
                      getAndSetBookedSlotsOfSelectedDate()
                    }}
                  >
                    Mark as completed
                  </EDVCButton>
                )}
                {clickedBookedSlotData?.status !== STATUS_SESSION_CANCELLED && (
                  <EDVCButton
                    invert
                    style={{ background: 'red', color: '#fff', border: '1px solid red' }}
                    disabled={false}
                    onClick={() => {
                      setCancelDialogData(clickedBookedSlotData)
                    }}
                  >
                    Cancel
                  </EDVCButton>
                )}
              </>
            )}
          </EDVCBookedSlotDetailsContainer>
        </Popover>

        {cancelDialogData && (
          <ConfirmCancelSessionDialog
            open={cancelDialogData}
            closeFunc={() => setCancelDialogData(null)}
            sessionData={cancelDialogData}
            afterCancelSuccess={getAndSetBookedSlotsOfSelectedDate}
          />
        )}

        {newBookingDialogOpen && (
          <NewBookingDialogComponent
            dialogTitle={`Book a Session with ${mentorProfileData?.name}`}
            dialogSecondaryTitle={`${eventDataForSelectedEventType?.title} (${moment?.(
              clickedWantedSlotData?.startTime,
            )
              .local()
              ?.format('h:mma')} - ${moment?.(clickedWantedSlotData?.endTime)
              .local()
              ?.format('h:mma')}) - ${
              platformOptions.find((item) => item.id === eventDataForSelectedEventType?.meetingSrc)
                ?.name
            }`}
            open={newBookingDialogOpen}
            handleCloseDialog={() => setNewBookingDialogOpen(false)}
            clinicId={selectedClinic?.id}
            clinicBrandId={selectedClinic?.clinicBrandId || ''}
            mentorProfileData={mentorProfileData}
            eventDataForSelectedEventType={eventDataForSelectedEventType}
            clickedWantedSlotData={clickedWantedSlotData}
            clickedWantedSlotAppointmentNumber={clickedWantedSlotAppointmentNumber}
            getAndSetBookedSlotsOfSelectedDate={getAndSetBookedSlotsOfSelectedDate}
            setNewBookingDialogOpen={setNewBookingDialogOpen}
            bookedSlotsInDay={bookedSlotsInDay}
            planDataForSelectedEventId={planDataForSelectedEventId}
            forMentor={forMentor}
            chambersData={chambersData}
            assistantProfileInThisClinic={assistantProfileInThisClinic}
          />
        )}

        <EditPatientDialogComponent
          dialogTitle="Patient Details"
          open={editPatientDialogOpen}
          handleCloseDialog={() => setEditPatientDialogOpen(false)}
          patientId={clickedBookedSlotData?.patientId}
          clinicId={selectedClinic?.id}
          clinicBrandId={selectedClinic?.clinicBrandId || ''}
          mentorProfileData={mentorProfileData}
        />

        <PendingActionsDCDialogComponent
          open={pendingActionsDialogOpen}
          handleCloseDialog={() => setPendingActionsDialogOpen(false)}
          thisBookedSlotData={clickedBookedSlotData}
          pendingActionsOfThisPatient={pendingActionsOfPerson?.filter(
            (item) => item?.patientId === clickedBookedSlotData?.patientId,
          )}
          refreshPendingActions={getAndSetBookedSlotsOfSelectedDate}
          clinicId={selectedClinic?.id}
          patientId={clickedBookedSlotData?.patientId}
          scheduleForNextVisit={scheduleForSelectedEventType}
          professionalDataForNextVisit={mentorProfileData}
          eventDataForNextVisit={eventDataForSelectedEventType}
          allProfessionalsDataForAssistant={allProfessionalsDataForAssistant}
          selectedClinic={selectedClinic}
        />

        <TherapyBookingDialogComponent
          open={therapyBookingDialogOpen}
          clinicLocationId={selectedClinic?.id}
          clinicBrandId={selectedClinic?.clinicBrandId}
          clinicLocationData={selectedClinic}
          handleCloseDialog={() => setTherapyBookingDialogOpen(false)}
          data={dataForTherapyBookingDialog}
          setFetchDataTriggerForBookingRequests={setFetchDataTriggerForBookingRequests}
        />

        {cancelledCount > 0 && (
          <div>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => setShowCancelledOnly((prev) => !prev)}
            >
              <u>{showCancelledOnly ? 'Show Booked' : 'Show Cancelled Only'}</u>
            </div>
            <div>Cancelled: {cancelledCount}</div>
          </div>
        )}

        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <EDVCEventsContainer>
            {eventTypesVisible?.map((eventTypeItem, eventTypeIndex) => {
              const thisEventClinic = thisClinicData
              return (
                <NewEventTypeButton
                  key={eventTypeIndex}
                  selected={selectedEventTypeId === eventTypeItem?.uid}
                  onClick={() => setSelectedEventTypeId(eventTypeItem?.uid)}
                >
                  {eventTypeItem?.title}
                  <span style={{ marginLeft: '8px' }}>
                    {eventTypeItem?.meetingSrc === 'aumhum' ? (
                      <VideoCameraFrontIcon />
                    ) : (
                      <ChairIcon />
                    )}
                  </span>
                  {thisEventClinic?.nickName && `(${thisEventClinic?.nickName})`}
                </NewEventTypeButton>
              )
            })}
          </EDVCEventsContainer>
          <div>
            {bookedSlotsInDayLoading ? (
              <Spinner animation="border" variant="dark" size="sm" />
            ) : (
              <OverlayTrigger placement="bottom" overlay={tooltipComp}>
                <StyledEventsRefreshIcon onClick={getAndSetBookedSlotsOfSelectedDate} />
              </OverlayTrigger>
            )}
          </div>
        </div>

        {!forMentor && selectedClinicBrandData && (
          <div
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() =>
              navigator.clipboard.writeText(
                `${APP_CONSTANTS.LIVE_APP_URL}/clinic/${selectedClinicBrandData?.slug}/id/${selectedClinic?.id}/${mentorProfileData?.slug}/${selectedEventTypeId}`,
              )
            }
          >
            Copy Self-Booking Link
          </div>
        )}

        <EDVCDateContainer>
          <div style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={onPrevClick}>
            {moment().format('DD-MM-YYYY') === selectedDate ? 'Yesterday' : 'Prev'}
          </div>
          <GenericDisplayFlexColumn aic>
            <div style={{ fontWeight: '700', textDecoration: 'underline' }}>
              {moment().format('DD-MM-YYYY') === selectedDate && 'Today'}
            </div>
            <div style={{ fontWeight: '700' }}>
              {selectedDate}, {moment(selectedDate, 'DD-MM-YYYY').format('dddd')} (
              {moment().format('Z')})
            </div>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => setChooseDateCalendarVisible((prev) => !prev)}
            >
              <u>{chooseDateCalendarVisible ? 'Hide Calendar' : 'Choose Date'}</u>
            </div>
            {chooseDateCalendarVisible && (
              <FCRCalendarContainer>
                <Calendar
                  onChange={(nextValue) => setSelectedDate(moment(nextValue)?.format('DD-MM-YYYY'))}
                  value={moment(selectedDate, 'DD-MM-YYYY').toDate()}
                  minDetail="decade"
                  showNeighboringMonth={false}
                  locale={sessionStorage.getItem('languageCode') || 'en'}
                />
              </FCRCalendarContainer>
            )}
            {forMentor &&
              (sendReportLoading ? (
                <div>sending report..</div>
              ) : (
                <div style={{ cursor: 'pointer' }} onClick={sendDayReportForDoctor}>
                  Get day&apos;s summary <SummarizeIcon />
                </div>
              ))}
          </GenericDisplayFlexColumn>
          <div style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={onNextClick}>
            {moment().format('DD-MM-YYYY') === selectedDate ? 'Tomorrow' : 'Next'}
          </div>
        </EDVCDateContainer>

        {/* for the first conditional container to show time now strip */}
        {moment().isBetween(
          moment(slotGroups?.[0]?.[0]?.startTime, 'YYYY-MM-DDTHH:mm:ssZ').startOf('day'),
          moment(slotGroups?.[0]?.[0]?.startTime, 'YYYY-MM-DDTHH:mm:ssZ'),
          null,
          '[]',
        ) && (
          <EDVCExtraSlotItemForTime>
            <EDVCPresentTimeLine ref={presentTimeLineRef} fromTopInPx={0}>
              <EDVCPresentTimeLineDot />
            </EDVCPresentTimeLine>
            <EDVCSlotItemTime>
              <EDVCSlotStartTime style={{ top: 'unset', right: 'unset' }}>
                {moment()?.format('hh:mmA')}
              </EDVCSlotStartTime>
            </EDVCSlotItemTime>
          </EDVCExtraSlotItemForTime>
        )}

        {slotGroups?.map((timeBlock, timeBlockIndex) => {
          return (
            <>
              <EDVCContainer key={timeBlockIndex}>
                {/* available slots */}
                {timeBlock?.map((slot, slotIndex) => (
                  <EDVCSlotItemContainer key={slotIndex} heightOfEachSlot={heightOfEachSlot}>
                    <EDVCSlotItemTime>
                      <EDVCSlotStartTime>
                        {moment(slot?.startTime)?.format('hh:mmA')}
                      </EDVCSlotStartTime>
                    </EDVCSlotItemTime>
                    <EDVCSlotItem
                      heightOfEachSlot={heightOfEachSlot}
                      onClick={() => {
                        setClickedWantedSlotData(slot)
                        setClickedWantedSlotAppointmentNumber(slotIndex + 1)
                        setNewBookingDialogOpen(true)
                      }}
                    ></EDVCSlotItem>
                  </EDVCSlotItemContainer>
                ))}
                {/* ends */}

                {/* booked slots overlay colored - */}
                {bookedSlotsInDay?.map((bookedSlot, bookedSlotIndex) => {
                  const startTimeOfTimeBlock = moment(timeBlock[0]?.startTime)
                  const endTimeOfTimeBlock = moment(timeBlock[timeBlock?.length - 1]?.endTime)
                  if (
                    moment(bookedSlot?.startTimeForDayCal).isBetween(
                      startTimeOfTimeBlock,
                      endTimeOfTimeBlock,
                      null,
                      '[]',
                    )
                  ) {
                    const durationOfBookedSlotInMins = moment(bookedSlot?.endTimeForDayCal)?.diff(
                      moment(bookedSlot?.startTimeForDayCal),
                      'minutes',
                    )
                    const minutesPassedSinceStartOfTimeBlock = moment(
                      bookedSlot?.startTimeForDayCal,
                    )?.diff(startTimeOfTimeBlock, 'minutes')
                    const pendingActionsForThisBookedEvent = pendingActionsOfPerson?.filter(
                      (item) => item?.bookedEventId === bookedSlot?.id,
                    )
                    const therapyBookingDataForThisEvent = pendingActionsForThisBookedEvent?.find(
                      (pa) => pa?.type === 'schedule-therapy',
                    )
                    const slotBlocked = selectedClinic?.id !== bookedSlot?.clinicId
                    return (
                      <EDVCSlotColoredItem
                        onClick={(event) => {
                          if (bookedSlot?.status !== STATUS_SESSION_CANCELLED) {
                            if (forMentor) {
                              handleBookedSlotOpenPopover(event)
                              setClickedBookedSlotData(bookedSlot)
                            } else {
                              handleBookedSlotOpenPopover(event)
                              setClickedBookedSlotData(bookedSlot)
                            }
                          }
                        }}
                        selected={false}
                        booked={true}
                        completed={bookedSlot?.markedCompleted}
                        blocked={slotBlocked}
                        fromTopInPx={
                          (minutesPassedSinceStartOfTimeBlock / gapDurationInMins) *
                          heightOfEachSlot
                        }
                        heightInPx={
                          (durationOfBookedSlotInMins / gapDurationInMins) * heightOfEachSlot
                        }
                        key={bookedSlotIndex}
                      >
                        <div className="d-flex justify-content-between">
                          {slotBlocked ? (
                            <span>Slot Unavailable: Not this Clinic&apos;s Booking</span>
                          ) : (
                            <>
                              <span>
                                {`${
                                  forMentor
                                    ? bookedSlot?.appointmentNumber
                                    : `${bookedSlot?.appointmentNumber} (${
                                        bookedSlot?.appNumber || bookedSlot?.appointmentNumber
                                      }-beta)`
                                }-` || ''}
                                {bookedSlot?.userName}{' '}
                                <span style={{ color: 'yellow' }}>
                                  {bookedSlot?.patientMeetingSeqNumber === 1 && '(New)'}
                                </span>
                                <span style={{ fontSize: '12px' }}>
                                  {' '}
                                  {bookedSlot?.createdTime &&
                                    `(Booked: ${dayjs
                                      .utc(bookedSlot?.createdTime)
                                      .local()
                                      .format('h:mm a')})`}
                                </span>
                                <span style={{ color: 'yellow' }}>
                                  {bookedSlot?.eventSubType === SUBTYPE_PRE_CONSULTATION &&
                                    '(Pre-Rx)'}
                                  {bookedSlot?.eventSubType === SUBTYPE_POST_CONSULTATION &&
                                    '(Post-Rx)'}
                                </span>
                                {bookedSlot?.status === STATUS_SESSION_CANCELLED && (
                                  <span style={{ color: 'red' }}>CANCELLED</span>
                                )}
                                <span style={{ color: 'yellow' }}>
                                  {bookedSlot?.feePaymentMode === 'aumhum' && ' (Paid on AUMHUM)'}
                                </span>
                                <span style={{ color: 'yellow' }}>
                                  {bookedSlot?.wasDelayedBecauseOfOverlaps && '(Shifted position)'}
                                </span>
                                <span style={{ marginLeft: '8px', position: 'relative' }}>
                                  {bookedSlot?.meetingSrc === 'offline' && <ChairIcon />}
                                  {bookedSlot?.meetingSrc === 'aumhum' && <VideoCameraFrontIcon />}
                                </span>
                              </span>
                              <div style={{ display: 'flex', gap: '3px', flexShrink: 0 }}>
                                {!forMentor && therapyBookingDataForThisEvent && (
                                  <EDVCSlotButton
                                    style={{ background: '#fcff02' }}
                                    onClick={(event) => {
                                      event.stopPropagation()
                                      handleNewTherapyRequest(
                                        therapyBookingDataForThisEvent,
                                        bookedSlot,
                                      )
                                    }}
                                  >
                                    Book Therapist
                                  </EDVCSlotButton>
                                )}

                                {!forMentor &&
                                  pendingActionsForThisBookedEvent &&
                                  pendingActionsForThisBookedEvent?.length > 0 && (
                                    <EDVCSlotButton
                                      style={{ background: '#fcff02' }}
                                      onClick={(event) => {
                                        setClickedBookedSlotData(bookedSlot)
                                        setPendingActionsDialogOpen(true)
                                        event.stopPropagation()
                                      }}
                                    >
                                      <InfoIcon sx={{ fontSize: '15px' }} />
                                      Pending Actions
                                    </EDVCSlotButton>
                                  )}

                                {/* {bookedSlot?.patientId && (
                                <EDVCSlotButton
                                  onClick={(event) => {
                                    setClickedBookedSlotData(bookedSlot)
                                    setEditPatientDialogOpen(true)
                                    event.stopPropagation()
                                  }}
                                >
                                  <InfoIcon sx={{ fontSize: '15px' }} />
                                  <PersonIcon sx={{ fontSize: '20px' }} />
                                </EDVCSlotButton>
                              )} */}

                                {canPersonPrintPrescription && bookedSlot?.markedCompleted && (
                                  <EDVCSlotButton
                                    onClick={() => {
                                      navigate(`prescription/${bookedSlot?.id}`)
                                    }}
                                  >
                                    <PrintIcon sx={{ fontSize: '20px' }} />
                                  </EDVCSlotButton>
                                )}

                                <MoreOptionsMenuForStrip
                                  handleNewTherapyRequest={handleNewTherapyRequest}
                                  therapyBookingDataForThisEvent={therapyBookingDataForThisEvent}
                                  bookedSlot={bookedSlot}
                                  setClickedBookedSlotData={setClickedBookedSlotData}
                                  setEditPatientDialogOpen={setEditPatientDialogOpen}
                                  forMentor={forMentor}
                                />
                              </div>
                            </>
                          )}
                        </div>
                        <div>
                          <ScreeningCheckUI bookedSlot={bookedSlot} />
                        </div>
                      </EDVCSlotColoredItem>
                    )
                  } else {
                    return null
                  }
                })}
                {/* ends*/}

                {Boolean(newBookingDialogOpen) &&
                  (() => {
                    const isBetweenCondition = moment(clickedWantedSlotData?.startTime).isBetween(
                      moment(timeBlock[0]?.startTime),
                      moment(timeBlock[timeBlock?.length - 1]?.endTime),
                      null,
                      '[]',
                    )
                    const fromTopInPxValue = getHeightFromTopInSlotGroup(
                      timeBlock,
                      clickedWantedSlotData,
                      gapDurationInMins,
                    )
                    if (isBetweenCondition) {
                      return (
                        <EDVCSlotColoredItem
                          selected={true}
                          booked={false}
                          fromTopInPx={fromTopInPxValue}
                          heightInPx={
                            (eventDataForSelectedEventType?.durationInMins / gapDurationInMins) *
                            heightOfEachSlot
                          }
                        ></EDVCSlotColoredItem>
                      )
                    }
                  })()}

                {moment().isBetween(
                  moment(timeBlock[0]?.startTime),
                  moment(timeBlock[timeBlock?.length - 1]?.endTime),
                  null,
                  '[]',
                ) &&
                  (() => {
                    return (
                      <EDVCPresentTimeLine
                        ref={presentTimeLineRef}
                        fromTopInPx={getHeightFromTopForCurrentTime(timeBlock, gapDurationInMins)}
                      >
                        <EDVCPresentTimeLineDot />
                      </EDVCPresentTimeLine>
                    )
                  })()}
              </EDVCContainer>

              {/* for the conditional container to show time now strip */}
              {moment().isBetween(
                moment(timeBlock[timeBlock?.length - 1]?.endTime, 'YYYY-MM-DDTHH:mm:ssZ'),
                moment(timeBlock[timeBlock?.length - 1]?.endTime, 'YYYY-MM-DDTHH:mm:ssZ').endOf(
                  'day',
                ),
                null,
                '[]',
              ) &&
                (() => {
                  if (timeBlockIndex === slotGroups?.length - 1) {
                    return (
                      <EDVCExtraSlotItemForTime>
                        <EDVCPresentTimeLine ref={presentTimeLineRef} fromTopInPx={0}>
                          <EDVCPresentTimeLineDot />
                        </EDVCPresentTimeLine>
                        <EDVCSlotItemTime>
                          <EDVCSlotStartTime style={{ top: 'unset', right: 'unset' }}>
                            {moment()?.format('hh:mmA')}
                          </EDVCSlotStartTime>
                        </EDVCSlotItemTime>
                      </EDVCExtraSlotItemForTime>
                    )
                  } else if (
                    moment().isBefore(
                      moment(
                        slotGroups?.[timeBlockIndex + 1]?.[0]?.startTime,
                        'YYYY-MM-DDTHH:mm:ssZ',
                      ),
                      null,
                      '[]',
                    )
                  ) {
                    return (
                      <EDVCExtraSlotItemForTime>
                        <EDVCPresentTimeLine ref={presentTimeLineRef} fromTopInPx={0}>
                          <EDVCPresentTimeLineDot />
                        </EDVCPresentTimeLine>
                        <EDVCSlotItemTime>
                          <EDVCSlotStartTime style={{ top: 'unset', right: 'unset' }}>
                            {moment()?.format('hh:mmA')}
                          </EDVCSlotStartTime>
                        </EDVCSlotItemTime>
                      </EDVCExtraSlotItemForTime>
                    )
                  }
                })()}
            </>
          )
        })}
      </EDVCParent>
    </>
  )
}

// new stuff components:

export const TherapyBookingDialogComponent = ({
  open,
  clinicLocationId,
  clinicBrandId,
  clinicLocationData,
  handleCloseDialog,
  data,
  bookingRequestsData,
  bookingRequestsDataLoading,
  setFetchDataTriggerForBookingRequests,
}) => {
  return (
    <BootstrapDialog
      onClose={handleCloseDialog}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperComponent={BootStrapDialogPaperComponent}
    >
      <div style={{ width: '90vw', height: '90vh', padding: '20px' }}>
        <CompositeBookingRequestCreation
          clinicLocationId={clinicLocationId}
          clinicBrandId={clinicBrandId}
          clinicLocationData={clinicLocationData}
          bookedSlot={data?.bookedSlot}
          bookingRequestsData={bookingRequestsData}
          bookingRequestsDataLoading={bookingRequestsDataLoading}
          neededTherapyBookingDataForThisEvent={data?.therapyBookingDataForThisEvent}
          handleCloseDialog={handleCloseDialog}
          setFetchDataTriggerForBookingRequests={setFetchDataTriggerForBookingRequests}
        />
      </div>
    </BootstrapDialog>
  )
}

export const NewBookingDialogComponent = ({
  dialogTitle,
  dialogSecondaryTitle,
  open,
  handleCloseDialog,
  clinicId,
  clinicBrandId,
  mentorProfileData,
  eventDataForSelectedEventType,
  clickedWantedSlotData,
  getAndSetBookedSlotsOfSelectedDate,
  setNewBookingDialogOpen,
  bookedSlotsInDay,
  planDataForSelectedEventId,
  forMentor,
  clickedWantedSlotAppointmentNumber,
  chambersData,
  assistantProfileInThisClinic,
}) => {
  // states:
  const [isNewPatient, setIsNewPatient] = useState(false)
  const [relatedToPatient, setRelatedToPatient] = useState()
  const [relatedPatientLoading, setRelatedPatientLoading] = useState(false)
  const [selectedPatient, setSelectedPatient] = useState(null)
  const [selectedPatientInputDataValidations, setSelectedPatientInputDataValidations] =
    useState(PATIENT_VALIDATION_FIELDS)
  const [selectedPatientChangesDisabled, setSelectedPatientChangesDisabled] = useState(true)
  const [tryBookingScreening, setTryBookingScreening] = useState(true)

  const [newPatientSubmissionLoading, setNewPatientSubmissionLoading] = useState(false)
  const [bookingLoading, setBookingLoading] = useState(false)
  const [patientUpdateLoading, setPatientUpdateLoading] = useState(false)

  const [newPatientInputData, setNewPatientInputData] = useState({ ...BLANK_PATIENT_DATA })
  const [newPatientInputDataValidations, setNewPatientInputDataValidations] =
    useState(PATIENT_VALIDATION_FIELDS)

  const [paymentDetail, setPaymentDetail] = useState({
    feePaid: undefined,
    feePaymentMode: 'cash',
    feePaidTxnId: '',
    currency:
      currency_list.find((item) =>
        areStringsEqualIgnoreCase(item.countryCode, localStorage.getItem('countryCode')),
      )?.code || 'usd',
  })

  const [autoFillStuffForNewPatientString, setAutoFillStuffForNewPatientString] = useState('')

  const [chosenChamberRoomId, setChosenChamberRoomId] = useState('')
  const [
    chamberRoomsForMentorAssistantClinicLocation,
    setChamberRoomsForMentorAssistantClinicLocation,
  ] = useState([])

  const [reasonForNotDoingScreening, setReasonForNotDoingScreening] = useState('')
  const [reasonIdForNotDoingScreening, setReasonIdForNotDoingScreening] = useState('')
  const [screenerAvailable, setScreenerAvailable] = useState(false)

  // is time enough to book?
  let isEnoughTimeToBook
  let thereIsOverlap = [] // with booked slots
  bookedSlotsInDay?.forEach((nonAvailableSlot) => {
    const UnavailableTimeSlotStart = moment(nonAvailableSlot?.startTimeForDayCal)
    const UnavailableTimeSlotEnd = moment(nonAvailableSlot?.endTimeForDayCal)

    if (
      moment(clickedWantedSlotData?.startTime)?.isSameOrAfter(UnavailableTimeSlotEnd) ||
      moment(clickedWantedSlotData?.endTime)?.isSameOrBefore(UnavailableTimeSlotStart)
    ) {
      thereIsOverlap?.push(false)
    } else {
      thereIsOverlap?.push(true)
    }
  })
  isEnoughTimeToBook = !thereIsOverlap?.some((element) => Boolean(element))
  ////////////

  // ask payment?
  const askPayment = Boolean(
    Boolean(planDataForSelectedEventId?.finalPrice) && !eventDataForSelectedEventType?.free,
  )

  async function selectPatientFunction(data) {
    const dataHere = { ...data }
    setIsNewPatient(false)
    setSelectedPatient({
      ...dataHere,
      age: getAgeFromDobWithTime(dataHere?.dateOfBirth),
    })
    setRelatedToPatient(null)
    if (dataHere?.primaryPatientProfile?.patientId) {
      setRelatedPatientLoading(true)
      const response = await getCPDataWithoutParams(
        `/api/secure/patient/list?patientId=${dataHere?.primaryPatientProfile?.patientId}&clinicId=${clinicId}`,
      )
      setRelatedPatientLoading(false)
      if (response.status === 200 && response?.data && response?.data?.[0]) {
        setRelatedToPatient(response?.data?.[0])
      } else {
        toast.error('Error finding related patient data. Contact support.')
      }
    }
  }

  function selectRelatedPatient(switchToPatientData) {
    const switchToPatientDataHere = { ...switchToPatientData }
    setSelectedPatient(switchToPatientDataHere)
    setRelatedToPatient(false)
  }

  function handleAddNewButtonClick(autoFillStuffForNewPatientString) {
    setSelectedPatient(null)
    setIsNewPatient(true)
    if (identifyStringUseForAutoFill(autoFillStuffForNewPatientString).isValid) {
      if (identifyStringUseForAutoFill(autoFillStuffForNewPatientString).type === 'email') {
        setNewPatientInputData({
          ...BLANK_PATIENT_DATA,
          email: autoFillStuffForNewPatientString,
        })
      } else if (identifyStringUseForAutoFill(autoFillStuffForNewPatientString).type === 'mobile') {
        if (isValidPhoneNumber(`+${autoFillStuffForNewPatientString}`)) {
          setNewPatientInputData({
            ...BLANK_PATIENT_DATA,
            mobile: autoFillStuffForNewPatientString,
          })
        } else {
          setNewPatientInputData({
            ...BLANK_PATIENT_DATA,
            mobile: `91${autoFillStuffForNewPatientString}`,
          })
        }
      } else if (identifyStringUseForAutoFill(autoFillStuffForNewPatientString).type === 'name') {
        setNewPatientInputData({
          ...BLANK_PATIENT_DATA,
          name: autoFillStuffForNewPatientString,
        })
      } else {
        setNewPatientInputData({ ...BLANK_PATIENT_DATA })
      }
    } else {
      setNewPatientInputData({ ...BLANK_PATIENT_DATA })
    }
  }

  function handleAddNewRelatedPatient(existingPatient) {
    const existingPatientHere = { ...existingPatient }
    setIsNewPatient(true)
    setRelatedToPatient(null)
    setSelectedPatient(null)
    setNewPatientInputData({
      ...BLANK_PATIENT_DATA,
      mobile: existingPatient?.mobile,
      email: existingPatient?.email,
      dependentRelation: 'relative',
      relatedToExistingPatient: existingPatientHere,
    })
  }

  useEffect(() => {
    setNewPatientInputDataValidations({
      ...PATIENT_VALIDATION_FIELDS,
      name: Boolean(newPatientInputData?.name),
      email: validateEmail(newPatientInputData?.email),
      mobile: isValidPhoneNumber(`+${removePlusFromBeginning(newPatientInputData?.mobile)}`),
      gender: Boolean(newPatientInputData?.gender),
      age: Boolean(newPatientInputData?.age),
    })
  }, [newPatientInputData])

  useEffect(() => {
    setSelectedPatientInputDataValidations({
      ...PATIENT_VALIDATION_FIELDS,
      name: Boolean(selectedPatient?.name),
      email: validateEmail(selectedPatient?.email),
      mobile: isValidPhoneNumber(`+${removePlusFromBeginning(selectedPatient?.mobile)}`),
      gender: Boolean(selectedPatient?.gender),
      age: Boolean(selectedPatient?.age),
    })
  }, [selectedPatient])

  useEffect(() => {
    if (
      Array.isArray(chambersData) &&
      eventDataForSelectedEventType?.meetingSrc === 'offline' &&
      !forMentor
    ) {
      const items = chambersData?.filter(
        (item) =>
          item?.clinicLocationId === clinicId &&
          item?.inPersonMentorsIds?.includes(mentorProfileData?.id) &&
          item?.clinicAssistantsIds?.includes(assistantProfileInThisClinic?.id),
      )
      if (items?.length > 0) {
        setChosenChamberRoomId(items?.[0]?.id)
        setChamberRoomsForMentorAssistantClinicLocation(items)
      } else {
        setChosenChamberRoomId('')
        setChamberRoomsForMentorAssistantClinicLocation([])
      }
    } else {
      setChosenChamberRoomId('')
      setChamberRoomsForMentorAssistantClinicLocation([])
    }
  }, [chambersData, mentorProfileData, eventDataForSelectedEventType, clinicId])

  return (
    <>
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperComponent={BootStrapDialogPaperComponent}
      >
        <DialogTitle
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <div style={{ padding: '16px' }}>
            <h5 style={{ fontWeight: '500' }}>{dialogTitle}</h5>
            <h6 style={{ fontWeight: '500' }}>{dialogSecondaryTitle}</h6>
          </div>
          <IconButton
            aria-label="close"
            onClick={() => {
              setSelectedPatient(null)
              setIsNewPatient(false)
              setNewPatientInputData({ ...BLANK_PATIENT_DATA })
              handleCloseDialog()
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <EDVCNewBookingContainer>
          {clinicId ? (
            <>
              {isEnoughTimeToBook ? (
                // main stuff here
                <>
                  {/* section one */}
                  <SearchSectionParent>
                    <GenericDisplayFlex
                      style={{
                        justifyContent: 'space-between',
                        gap: '15px',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div style={{ width: '100%' }}>
                        <AsyncPaginate
                          value={selectedPatient}
                          isClearable={true}
                          placeholder="Search Patient"
                          loadOptions={loadOptionsForExistingPatient}
                          components={{
                            NoOptionsMessage: CustomNoOptionsMessage,
                            Placeholder: CustomPlaceholder,
                            Option: CustomOption,
                          }}
                          styles={{
                            menuList: (provided, state) => ({
                              ...provided,
                              maxHeight: '200px', // Set the desired height here
                              overflowY: 'scroll',
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              display: 'none', // Hides the dropdown arrow
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: 'none', // Optionally hides the separator line
                            }),
                          }}
                          getOptionValue={(option) => option.id}
                          getOptionLabel={(option) => getLabelForAlreadyPatientOption(option)}
                          onChange={selectPatientFunction}
                          debounceTimeout={500}
                          additional={{
                            page: 1,
                            clinicId,
                            clinicBrandId,
                            setAutoFillStuffForNewPatientString,
                          }}
                        />
                        <div style={{ fontSize: '14px' }}>
                          <i>Use Patient No./Name/Mobile/Email</i>
                        </div>
                      </div>
                      <span style={{ marginTop: '6px' }}>{' or '}</span>
                      {!isNewPatient && !Boolean(selectedPatient) && (
                        <MuiButton
                          variant="contained"
                          sx={{ flexShrink: 0 }}
                          onClick={() => {
                            handleAddNewButtonClick(autoFillStuffForNewPatientString)
                          }}
                        >
                          Add New
                        </MuiButton>
                      )}
                      <MuiButton
                        variant="text"
                        onClick={() => {
                          setSelectedPatient(null)
                          setRelatedToPatient(null)
                          setIsNewPatient(false)
                          setNewPatientInputData({ ...BLANK_PATIENT_DATA })
                        }}
                      >
                        Reset
                      </MuiButton>
                    </GenericDisplayFlex>
                  </SearchSectionParent>
                  <hr />

                  {/* isNewPatient is marked true and there is no selected patient, patient creation possible */}
                  {isNewPatient && !Boolean(selectedPatient) && (
                    <>
                      {newPatientInputData?.relatedToExistingPatient &&
                        newPatientInputData?.dependentRelation && (
                          <div style={{ marginBottom: '1rem' }}>
                            Choose Relationship:{' '}
                            <b>{newPatientInputData?.relatedToExistingPatient?.name}&apos;s</b>
                            <CustomHTMLSelect
                              value={newPatientInputData?.dependentRelation}
                              onChange={(e) => {
                                setNewPatientInputData((prev) => ({
                                  ...prev,
                                  dependentRelation: e.target.value,
                                }))
                              }}
                              getOptionLabel={(data) => data?.label}
                              getOptionValue={(data) => data?.value}
                              options={RELATIONSHIP_OPTIONS}
                            />
                          </div>
                        )}
                      <PIParent>
                        <PISection>
                          {/* Name */}
                          <PISectionItem>
                            <PISectionTitle>Name*</PISectionTitle>
                            <div>
                              <PISectionInput
                                type="text"
                                value={newPatientInputData?.name}
                                onChange={(e) =>
                                  setNewPatientInputData((prev) => ({
                                    ...prev,
                                    name: e.target.value,
                                  }))
                                }
                              />
                              {!newPatientInputDataValidations?.name && (
                                <PISectionValidation>Required</PISectionValidation>
                              )}
                            </div>
                          </PISectionItem>
                          {/* Gender */}
                          <PISectionItem>
                            <PISectionTitle>Gender*</PISectionTitle>
                            <div>
                              <RectangleRadioButton
                                options={[
                                  { label: 'Male', id: 'male' },
                                  { label: 'Female', id: 'female' },
                                  { label: 'Other', id: 'nonBinary' },
                                ]}
                                selectedId={newPatientInputData?.gender}
                                selectionFunction={(id) =>
                                  setNewPatientInputData((prev) => ({ ...prev, gender: id }))
                                }
                              />
                              {!newPatientInputDataValidations?.gender && (
                                <PISectionValidation>Choose one</PISectionValidation>
                              )}
                            </div>
                          </PISectionItem>
                          {/* Age */}
                          <PISectionItem>
                            <PISectionTitle>Age*</PISectionTitle>
                            <div>
                              <PISectionInput
                                type="number"
                                value={newPatientInputData?.age}
                                onChange={(e) =>
                                  setNewPatientInputData((prev) => ({
                                    ...prev,
                                    age: e.target.value,
                                  }))
                                }
                              />
                              {!newPatientInputDataValidations?.age && (
                                <PISectionValidation>Required</PISectionValidation>
                              )}
                            </div>
                          </PISectionItem>
                          {/* Mobile */}
                          <PISectionItem>
                            <PISectionTitle>Mobile*</PISectionTitle>
                            <div>
                              <EDVCPhoneInput
                                disabled={
                                  newPatientInputData?.relatedToExistingPatient &&
                                  newPatientInputData?.dependentRelation
                                }
                                specialLabel=""
                                placeholder="Mobile Number"
                                country={localStorage.getItem('countryCode')?.toLowerCase() || 'in'}
                                value={newPatientInputData?.mobile}
                                onChange={(value) =>
                                  setNewPatientInputData((prev) => ({ ...prev, mobile: value }))
                                }
                              />
                              {!newPatientInputDataValidations?.email &&
                                !newPatientInputDataValidations?.mobile && (
                                  <PISectionValidation>
                                    Mobile or Email required
                                  </PISectionValidation>
                                )}
                            </div>
                          </PISectionItem>
                        </PISection>
                        <PISection>
                          {/* Email */}
                          <PISectionItem>
                            <PISectionTitle>Email</PISectionTitle>
                            <div>
                              <PISectionInput
                                disabled={
                                  newPatientInputData?.relatedToExistingPatient &&
                                  newPatientInputData?.dependentRelation
                                }
                                type="text"
                                value={newPatientInputData?.email}
                                onChange={(e) =>
                                  setNewPatientInputData((prev) => ({
                                    ...prev,
                                    email: e.target.value.trim(),
                                  }))
                                }
                              />
                              {!newPatientInputDataValidations?.email &&
                                !newPatientInputDataValidations?.mobile && (
                                  <PISectionValidation>
                                    Mobile or Email required
                                  </PISectionValidation>
                                )}
                            </div>
                          </PISectionItem>
                          {/* Address */}
                          <PISectionItem>
                            <PISectionTitle>Address</PISectionTitle>
                            <PISectionInput
                              type="text"
                              value={newPatientInputData?.address}
                              onChange={(e) =>
                                setNewPatientInputData((prev) => ({
                                  ...prev,
                                  address: e.target.value,
                                }))
                              }
                            />
                          </PISectionItem>
                          {/* City */}
                          <PISectionItem>
                            <PISectionTitle>City</PISectionTitle>
                            <PISectionInput
                              type="text"
                              value={newPatientInputData?.city}
                              onChange={(e) =>
                                setNewPatientInputData((prev) => ({
                                  ...prev,
                                  city: e.target.value,
                                }))
                              }
                            />
                          </PISectionItem>
                          {/* Pincode */}
                          <PISectionItem>
                            <PISectionTitle>Pincode</PISectionTitle>
                            <PISectionInput
                              type="number"
                              value={newPatientInputData?.pincode}
                              onChange={(e) =>
                                setNewPatientInputData((prev) => ({
                                  ...prev,
                                  pincode: e.target.value,
                                }))
                              }
                            />
                          </PISectionItem>
                          {/* Note */}
                          <PISectionItem>
                            <PISectionTitle>Note</PISectionTitle>
                            <PISectionInput
                              type="text"
                              value={newPatientInputData?.note}
                              onChange={(e) =>
                                setNewPatientInputData((prev) => ({
                                  ...prev,
                                  note: e.target.value,
                                }))
                              }
                            />
                          </PISectionItem>
                          {/* Referred By */}
                          <PISectionItem>
                            <PISectionTitle>Referred By</PISectionTitle>
                            <PISectionInput
                              type="text"
                              value={newPatientInputData?.referredBy}
                              onChange={(e) =>
                                setNewPatientInputData((prev) => ({
                                  ...prev,
                                  referredBy: e.target.value,
                                }))
                              }
                            />
                          </PISectionItem>
                        </PISection>
                      </PIParent>
                      <div style={{ marginTop: '10px' }}>
                        {newPatientSubmissionLoading ? (
                          <Spinner animation="border" variant="dark" size="md" />
                        ) : (
                          <MuiButton
                            variant="contained"
                            disabled={
                              !(
                                newPatientInputDataValidations?.name &&
                                (newPatientInputDataValidations?.email ||
                                  newPatientInputDataValidations?.mobile) &&
                                newPatientInputDataValidations?.gender &&
                                newPatientInputDataValidations?.age
                              ) || newPatientSubmissionLoading
                            }
                            onClick={() => {
                              createNewPatient(
                                clinicId,
                                clinicBrandId,
                                mentorProfileData,
                                newPatientInputData,
                                setNewPatientSubmissionLoading,
                                forMentor,
                                setSelectedPatient,
                                setNewPatientInputData,
                                setIsNewPatient,
                                setRelatedToPatient,
                                setRelatedPatientLoading,
                              )
                            }}
                          >
                            Add Patient
                          </MuiButton>
                        )}
                      </div>
                    </>
                  )}

                  {/* patient is selected and either below code is used to either update the details of the patient or create a booking for him */}
                  {selectedPatient && (
                    <>
                      {/* edit patient/add related buttons */}

                      <div
                        style={{
                          margin: '1rem 0',
                          display: 'flex',
                          gap: '16px',
                          justifyContent: 'right',
                        }}
                      >
                        {selectedPatientChangesDisabled && (
                          <MuiButton
                            sx={{ textDecoration: 'underline' }}
                            startIcon={<EditIcon />}
                            variant="text"
                            onClick={() => {
                              setSelectedPatientChangesDisabled(false)
                            }}
                          >
                            Edit Patient
                          </MuiButton>
                        )}
                        {/* add related patient button */}
                        {selectedPatient &&
                          selectedPatientChangesDisabled &&
                          !Boolean(selectedPatient?.primaryPatientProfile?.patientId) && (
                            <MuiButton
                              sx={{ textDecoration: 'underline' }}
                              endIcon={<AddIcon />}
                              variant="text"
                              onClick={() => handleAddNewRelatedPatient(selectedPatient)}
                            >
                              Add Related Patient
                            </MuiButton>
                          )}
                      </div>

                      {/* related patient section */}
                      {selectedPatient?.primaryPatientProfile?.patientId &&
                        (relatedPatientLoading ? (
                          <div>
                            <Spinner animation="border" variant="dark" size="sm" />
                          </div>
                        ) : (
                          <div
                            style={{ padding: '1rem', background: '#E7F3FF', marginBottom: '1rem' }}
                          >
                            <div>
                              <b>{`${relatedToPatient?.name}'s ${selectedPatient?.primaryPatientProfile?.dependentRelation}`}</b>
                            </div>
                            <div
                              style={{ fontSize: '12px', cursor: 'pointer' }}
                              onClick={() => selectRelatedPatient(relatedToPatient)}
                            >
                              <u>
                                Select {relatedToPatient?.name} (
                                {relatedToPatient?.dateOfBirth &&
                                  getAgeFromDobWithTime(relatedToPatient?.dateOfBirth)}
                                /{relatedToPatient?.gender})?
                              </u>
                            </div>
                          </div>
                        ))}

                      <PIParent>
                        {selectedPatientChangesDisabled ? (
                          <div className="d-flex flex-column">
                            <div>
                              <span>
                                <b>Patient Details: </b>
                              </span>
                              <span>
                                {selectedPatient?.name} ({selectedPatient?.age}/
                                {GENDER_LIST_OFFLINE.find(
                                  (item) => item.value === selectedPatient?.gender,
                                )?.label || '?'}
                                )
                              </span>
                            </div>
                            <div>
                              <span>
                                <b>Patient Number: </b>
                              </span>
                              <span>{selectedPatient?.patientNumber || '--'}</span>
                            </div>
                          </div>
                        ) : (
                          <>
                            <PISection>
                              {/* Name */}
                              <PISectionItem>
                                <PISectionTitle>Name*</PISectionTitle>
                                <div>
                                  <PISectionInput
                                    disabled={selectedPatientChangesDisabled}
                                    type="text"
                                    value={selectedPatient?.name}
                                    onChange={(e) =>
                                      setSelectedPatient((prev) => ({
                                        ...prev,
                                        name: e.target.value,
                                      }))
                                    }
                                  />
                                  {!selectedPatientInputDataValidations?.name && (
                                    <PISectionValidation>Required</PISectionValidation>
                                  )}
                                </div>
                              </PISectionItem>
                              {/* Gender */}
                              <PISectionItem>
                                <PISectionTitle>Gender*</PISectionTitle>
                                <div>
                                  <RectangleRadioButton
                                    disabled={selectedPatientChangesDisabled}
                                    options={[
                                      { label: 'Male', id: 'male' },
                                      { label: 'Female', id: 'female' },
                                      { label: 'Other', id: 'nonBinary' },
                                    ]}
                                    selectedId={selectedPatient?.gender}
                                    selectionFunction={(id) =>
                                      setSelectedPatient((prev) => ({ ...prev, gender: id }))
                                    }
                                  />
                                  {!selectedPatientInputDataValidations?.gender && (
                                    <PISectionValidation>Choose one</PISectionValidation>
                                  )}
                                </div>
                              </PISectionItem>
                              {/* Age */}
                              <PISectionItem>
                                <PISectionTitle>Age*</PISectionTitle>
                                <div>
                                  <PISectionInput
                                    disabled={selectedPatientChangesDisabled}
                                    type="number"
                                    value={selectedPatient?.age}
                                    onChange={(e) =>
                                      setSelectedPatient((prev) => ({
                                        ...prev,
                                        age: e.target.value,
                                      }))
                                    }
                                  />
                                  {!selectedPatientInputDataValidations?.age && (
                                    <PISectionValidation>Required</PISectionValidation>
                                  )}
                                </div>
                              </PISectionItem>
                              {/* Mobile */}
                              <PISectionItem>
                                <PISectionTitle>Mobile*</PISectionTitle>
                                <div>
                                  <EDVCPhoneInput
                                    disabled={selectedPatientChangesDisabled}
                                    specialLabel=""
                                    placeholder="Mobile Number"
                                    country={
                                      localStorage.getItem('countryCode')?.toLowerCase() || 'in'
                                    }
                                    value={removePlusFromBeginning(selectedPatient?.mobile)}
                                    onChange={(value) => {
                                      setSelectedPatient((prev) => ({ ...prev, mobile: value }))
                                    }}
                                  />
                                  {!selectedPatientInputDataValidations?.email &&
                                    !selectedPatientInputDataValidations?.mobile && (
                                      <PISectionValidation>
                                        Mobile or Email required
                                      </PISectionValidation>
                                    )}
                                </div>
                              </PISectionItem>
                              <div style={{ marginTop: '64px' }}>
                                {patientUpdateLoading ? (
                                  <Spinner animation="border" variant="dark" size="sm" />
                                ) : (
                                  <MuiButton
                                    variant="contained"
                                    disabled={(() => {
                                      if (!selectedPatientChangesDisabled) {
                                        return (
                                          !(
                                            selectedPatientInputDataValidations?.name &&
                                            (selectedPatientInputDataValidations?.email ||
                                              selectedPatientInputDataValidations?.mobile) &&
                                            selectedPatientInputDataValidations?.gender &&
                                            selectedPatientInputDataValidations?.age
                                          ) || patientUpdateLoading
                                        )
                                      }
                                      return false
                                    })()}
                                    onClick={() => {
                                      handleUpdatePatientDetails(
                                        selectedPatient?.id,
                                        clinicId,
                                        clinicBrandId,
                                        mentorProfileData,
                                        selectedPatient,
                                        setPatientUpdateLoading,
                                        setSelectedPatientChangesDisabled,
                                      )
                                    }}
                                  >
                                    {!selectedPatientChangesDisabled && 'Update Patient'}
                                  </MuiButton>
                                )}
                              </div>
                            </PISection>
                            <PISection>
                              {/* Email */}
                              <PISectionItem>
                                <PISectionTitle>Email</PISectionTitle>
                                <div>
                                  <PISectionInput
                                    type="text"
                                    disabled={selectedPatientChangesDisabled}
                                    value={selectedPatient?.email}
                                    onChange={(e) => {
                                      setSelectedPatient((prev) => ({
                                        ...prev,
                                        email: e.target.value.trim(),
                                      }))
                                    }}
                                  />
                                  {!selectedPatientInputDataValidations?.email &&
                                    !selectedPatientInputDataValidations?.mobile && (
                                      <PISectionValidation>
                                        Mobile or Email required
                                      </PISectionValidation>
                                    )}
                                </div>
                              </PISectionItem>
                              {/* Address */}
                              <PISectionItem>
                                <PISectionTitle>Address</PISectionTitle>
                                <PISectionInput
                                  type="text"
                                  disabled={selectedPatientChangesDisabled}
                                  value={selectedPatient?.address}
                                  onChange={(e) =>
                                    setSelectedPatient((prev) => ({
                                      ...prev,
                                      address: e.target.value,
                                    }))
                                  }
                                />
                              </PISectionItem>
                              {/* City */}
                              <PISectionItem>
                                <PISectionTitle>City</PISectionTitle>
                                <PISectionInput
                                  type="text"
                                  disabled={selectedPatientChangesDisabled}
                                  value={selectedPatient?.city}
                                  onChange={(e) =>
                                    setSelectedPatient((prev) => ({
                                      ...prev,
                                      city: e.target.value,
                                    }))
                                  }
                                />
                              </PISectionItem>
                              {/* Pincode */}
                              <PISectionItem>
                                <PISectionTitle>Pincode</PISectionTitle>
                                <PISectionInput
                                  type="number"
                                  disabled={selectedPatientChangesDisabled}
                                  value={selectedPatient?.pincode}
                                  onChange={(e) =>
                                    setSelectedPatient((prev) => ({
                                      ...prev,
                                      pincode: e.target.value,
                                    }))
                                  }
                                />
                              </PISectionItem>
                              {/* Note */}
                              <PISectionItem>
                                <PISectionTitle>Note</PISectionTitle>
                                <PISectionInput
                                  type="text"
                                  disabled={selectedPatientChangesDisabled}
                                  value={selectedPatient?.note}
                                  onChange={(e) =>
                                    setSelectedPatient((prev) => ({
                                      ...prev,
                                      note: e.target.value,
                                    }))
                                  }
                                />
                              </PISectionItem>
                              {/* Referred By */}
                              <PISectionItem>
                                <PISectionTitle>Referred By</PISectionTitle>
                                <PISectionInput
                                  type="text"
                                  disabled={selectedPatientChangesDisabled}
                                  value={selectedPatient?.referredBy}
                                  onChange={(e) =>
                                    setSelectedPatient((prev) => ({
                                      ...prev,
                                      referredBy: e.target.value,
                                    }))
                                  }
                                />
                              </PISectionItem>
                            </PISection>
                          </>
                        )}
                      </PIParent>

                      {selectedPatientChangesDisabled && (
                        <>
                          <hr />
                          {askPayment && (
                            <div>
                              {chamberRoomsForMentorAssistantClinicLocation?.length > 0 &&
                                eventDataForSelectedEventType?.meetingSrc === 'offline' && (
                                  <>
                                    <div style={{ marginTop: '5px' }}>Chamber Room</div>
                                    <EDVCBookNewSlotSelect
                                      value={chosenChamberRoomId}
                                      onChange={(e) => {
                                        setChosenChamberRoomId(e.target.value.id)
                                      }}
                                    >
                                      {chamberRoomsForMentorAssistantClinicLocation?.map(
                                        (room, roomIndex) => (
                                          <option value={room.id} key={roomIndex}>
                                            {room?.title || room?.id}
                                          </option>
                                        ),
                                      )}
                                    </EDVCBookNewSlotSelect>
                                  </>
                                )}
                              <>
                                <div style={{ marginTop: '5px' }}>
                                  Fee Collected (Default:{' '}
                                  {(planDataForSelectedEventId?.currency || '')?.toUpperCase()}{' '}
                                  {planDataForSelectedEventId?.finalPrice})
                                </div>
                                <div style={{ display: 'flex' }}>
                                  <EDVCBookNewSlotSelect
                                    style={{ width: '80px', borderRadius: '4px 0 0 4px' }}
                                    value={paymentDetail?.currency}
                                    onChange={(e) =>
                                      setPaymentDetail((prev) => ({
                                        ...prev,
                                        currency: e.target.value,
                                      }))
                                    }
                                  >
                                    {currency_list?.map((currencyData, currencyDataIndex) => (
                                      <option value={currencyData.code} key={currencyDataIndex}>
                                        {currencyData.code?.toUpperCase()}
                                      </option>
                                    ))}
                                  </EDVCBookNewSlotSelect>
                                  <PISectionInput
                                    style={{ borderRadius: '0 4px 4px 0' }}
                                    value={paymentDetail?.feePaid}
                                    onChange={(e) =>
                                      setPaymentDetail((prev) => ({
                                        ...prev,
                                        feePaid: e.target.value,
                                      }))
                                    }
                                  />
                                </div>
                              </>
                              <>
                                <div style={{ marginTop: '5px' }}>Payment Mode</div>
                                <EDVCBookNewSlotSelect
                                  value={paymentDetail?.feePaymentMode}
                                  onChange={(e) =>
                                    setPaymentDetail((prev) => ({
                                      ...prev,
                                      feePaymentMode: e.target.value,
                                    }))
                                  }
                                >
                                  {OFFLINE_PAYMENT_METHODS_LIST?.map(
                                    (paymentMethod, paymentMethodIndex) => (
                                      <option value={paymentMethod.id} key={paymentMethodIndex}>
                                        {paymentMethod.title}
                                      </option>
                                    ),
                                  )}
                                </EDVCBookNewSlotSelect>
                              </>
                              {paymentDetail?.feePaymentMode !== 'cash' && (
                                <>
                                  <div style={{ marginTop: '5px' }}>Transaction ID</div>
                                  <PISectionInput
                                    value={paymentDetail?.feePaidTxnId}
                                    onChange={(e) =>
                                      setPaymentDetail((prev) => ({
                                        ...prev,
                                        feePaidTxnId: e.target.value,
                                      }))
                                    }
                                  />
                                </>
                              )}
                            </div>
                          )}
                          {eventDataForSelectedEventType?.type ===
                            allAvailableEventTypes.consultation &&
                            !forMentor && (
                              <div className="d-flex flex-column w-100">
                                <div className="d-flex align-items-center w-100">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={tryBookingScreening}
                                        onChange={(e) => {
                                          setTryBookingScreening(e.target.checked)
                                        }}
                                        icon={<CancelIcon style={{ color: 'red' }} />}
                                        checkedIcon={
                                          screenerAvailable ? (
                                            <CheckCircleIcon style={{ color: 'green' }} />
                                          ) : (
                                            <Error sx={{ color: '#ffaa09' }} />
                                          )
                                        }
                                      />
                                    }
                                    color="secondary"
                                    label="Book with Screening"
                                  />
                                  <AvailableScreenerComponent
                                    eventBeingBooked={{
                                      apiParam: 'booked-event-info',
                                      mentorId: mentorProfileData?.id,
                                      userId: '',
                                      startTime: moment
                                        ?.utc(clickedWantedSlotData?.startTime)
                                        ?.format('YYYY-MM-DD HH:mm:ss'),
                                      endTime: moment
                                        ?.utc(clickedWantedSlotData?.endTime)
                                        ?.format('YYYY-MM-DD HH:mm:ss'),
                                      eventType: eventDataForSelectedEventType?.type,
                                      chamberRoomId: chosenChamberRoomId,
                                      eventTypeId: eventDataForSelectedEventType?.uid,
                                      subscriptionPlanId:
                                        eventDataForSelectedEventType?.subscriptionPlanId,
                                      patientId: selectedPatient?.id,
                                      id: '',
                                      appointmentNumber: clickedWantedSlotAppointmentNumber,
                                      feePaid: askPayment ? paymentDetail?.feePaid : '',
                                      currency: paymentDetail?.currency || 'inr',
                                      feePaymentMode: askPayment
                                        ? paymentDetail?.feePaymentMode
                                        : '',
                                      feePaidTxnId: askPayment ? paymentDetail?.feePaidTxnId : '',
                                      clinicId: clinicId || '',
                                      clinicBrandId: clinicBrandId || '',
                                    }}
                                    setScreenerAvailable={setScreenerAvailable}
                                  />
                                </div>
                                {!tryBookingScreening && (
                                  <NewBookingDialogReasonForNotDoingScreeningForm
                                    reasonForNotBooking={reasonForNotDoingScreening}
                                    setReasonIdForNotDoingScreening={
                                      setReasonIdForNotDoingScreening
                                    }
                                    setReasonForNotBooking={setReasonForNotDoingScreening}
                                    screenerAvailable={screenerAvailable}
                                    reasonForNotBookingScreeningId={reasonIdForNotDoingScreening}
                                  />
                                )}
                              </div>
                            )}
                          {bookingLoading ? (
                            <Spinner animation="border" variant="dark" size="md" />
                          ) : (
                            <div style={{ display: 'flex', gap: '16px' }}>
                              <MuiButton
                                sx={{ marginTop: '10px', display: 'block' }}
                                variant="contained"
                                disabled={(() => {
                                  let toReturn = false
                                  if (askPayment) {
                                    if (paymentDetail?.feePaid && paymentDetail?.feePaymentMode) {
                                      if (paymentDetail?.feePaymentMode === 'cash') {
                                        toReturn = false
                                      } else if (paymentDetail?.feePaymentMode !== 'cash') {
                                        toReturn = false
                                      } else {
                                        toReturn = true
                                      }
                                    } else {
                                      toReturn = true
                                    }
                                  } else {
                                    toReturn = false
                                  }
                                  if (
                                    eventDataForSelectedEventType?.type ===
                                      allAvailableEventTypes.consultation &&
                                    !forMentor
                                  ) {
                                    if (!tryBookingScreening) {
                                      if (!reasonForNotDoingScreening) {
                                        toReturn = true
                                      }
                                    }
                                  }

                                  return toReturn
                                })()}
                                onClick={() => {
                                  makeBookingInDayViewCalendar(
                                    forMentor,
                                    clinicId,
                                    clinicBrandId,
                                    mentorProfileData?.id,
                                    askPayment,
                                    clickedWantedSlotData,
                                    clickedWantedSlotAppointmentNumber,
                                    eventDataForSelectedEventType,
                                    selectedPatient,
                                    getAndSetBookedSlotsOfSelectedDate,
                                    setNewBookingDialogOpen,
                                    {
                                      feePaid: paymentDetail?.feePaid,
                                      feePaymentMode: paymentDetail?.feePaymentMode,
                                      feePaidTxnId: paymentDetail?.feePaidTxnId,
                                      currency: paymentDetail?.currency,
                                    },
                                    setBookingLoading,
                                    setSelectedPatient,
                                    setIsNewPatient,
                                    setNewPatientInputData,
                                    chosenChamberRoomId,
                                    tryBookingScreening,
                                    false,
                                    getScreeningReasonMessageObject({
                                      eventType: eventDataForSelectedEventType?.type,
                                      isScreenerAvailable: screenerAvailable,
                                      isTryingToBookScreening: tryBookingScreening,
                                      reasonIdForNotDoingScreening: reasonIdForNotDoingScreening,
                                      reasonForNotDoingScreening: reasonForNotDoingScreening,
                                    }),
                                  )
                                }}
                              >
                                Book Now
                              </MuiButton>
                              {forMentor && (
                                <MuiButton
                                  sx={{ marginTop: '10px', display: 'block' }}
                                  variant="contained"
                                  disabled={(() => {
                                    if (askPayment) {
                                      if (paymentDetail?.feePaid && paymentDetail?.feePaymentMode) {
                                        if (paymentDetail?.feePaymentMode === 'cash') {
                                          return false
                                        } else if (paymentDetail?.feePaymentMode !== 'cash') {
                                          return false
                                        } else {
                                          return true
                                        }
                                      } else {
                                        return true
                                      }
                                    } else {
                                      return false
                                    }
                                  })()}
                                  onClick={() => {
                                    makeBookingInDayViewCalendar(
                                      forMentor,
                                      clinicId,
                                      clinicBrandId,
                                      mentorProfileData?.id,
                                      askPayment,
                                      clickedWantedSlotData,
                                      clickedWantedSlotAppointmentNumber,
                                      eventDataForSelectedEventType,
                                      selectedPatient,
                                      getAndSetBookedSlotsOfSelectedDate,
                                      setNewBookingDialogOpen,
                                      {
                                        feePaid: paymentDetail?.feePaid,
                                        feePaymentMode: paymentDetail?.feePaymentMode,
                                        feePaidTxnId: paymentDetail?.feePaidTxnId,
                                        currency: paymentDetail?.currency,
                                      },
                                      setBookingLoading,
                                      setSelectedPatient,
                                      setIsNewPatient,
                                      setNewPatientInputData,
                                      chosenChamberRoomId,
                                      tryBookingScreening,
                                      true,
                                    )
                                  }}
                                >
                                  Hold Slot & send Link to Pay
                                </MuiButton>
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <div
                  style={{
                    fontWeight: 500,
                    textAlign: 'center',
                    color: '#b73c30',
                    marginBottom: '20px',
                  }}
                >
                  Not enough time to book this event
                </div>
              )}
            </>
          ) : (
            <div style={{ fontWeight: 700, textAlign: 'center', color: '#b73c30' }}>
              The event needs to be associated with a clinic for booking slots from here.
            </div>
          )}
        </EDVCNewBookingContainer>
      </BootstrapDialog>
    </>
  )
}

export const EditPatientDialogComponent = ({
  dialogTitle,
  open,
  handleCloseDialog,
  patientId,
  clinicId,
  clinicBrandId,
  mentorProfileData,
}) => {
  return (
    <>
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperComponent={BootStrapDialogPaperComponent}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {dialogTitle}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <PatientForm
          newPatient={false}
          patientId={patientId}
          showSeparateDialogActions={true}
          clinicId={clinicId}
          clinicBrandId={clinicBrandId}
          mentorProfileData={mentorProfileData}
        />
      </BootstrapDialog>
    </>
  )
}

export const PendingActionsDCDialogComponent = ({
  open,
  handleCloseDialog,
  thisBookedSlotData,
  pendingActionsOfThisPatient,
  refreshPendingActions,

  patientId,
  clinicId,
  professionalDataForNextVisit,
  scheduleForNextVisit,
  eventDataForNextVisit,

  allProfessionalsDataForAssistant,
  selectedClinic,
}) => {
  const thisBookingPendingActions = pendingActionsOfThisPatient?.filter(
    (item) => item?.bookedEventId === thisBookedSlotData?.id,
  )
  const otherBookingPendingActions = pendingActionsOfThisPatient?.filter(
    (item) => item?.bookedEventId !== thisBookedSlotData?.id,
  )

  return (
    <>
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperComponent={BootStrapDialogPaperComponent}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Pending Actions for {thisBookedSlotData?.userName}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <div style={{ padding: '0 20px 20px', width: '700px' }}>
          <hr />
          <h6>This Booking&apos;s pending actions:</h6>
          <div>
            <b>{thisBookingPendingActions?.length === 0 && 'No pending actions'}</b>
          </div>
          {thisBookingPendingActions?.map((thisBookingActionItem, thisBookingActionItemIndex) => {
            return (
              <div
                key={thisBookingActionItemIndex}
                style={{ border: '1px solid #CECECE', borderRadius: '5px', marginBottom: '5px' }}
              >
                <RenderAction
                  actionItem={thisBookingActionItem}
                  actionItemIndex={thisBookingActionItemIndex}
                  refreshPendingActions={refreshPendingActions}
                  patientId={patientId}
                  clinicId={clinicId}
                  eventIdForNextBooking={eventDataForNextVisit?.uid}
                  eventDataForNextBooking={eventDataForNextVisit}
                  scheduleForThisEventNextBooking={scheduleForNextVisit}
                  professionalDataForNextVisit={professionalDataForNextVisit}
                  allProfessionalsDataForAssistant={allProfessionalsDataForAssistant}
                  selectedClinic={selectedClinic}
                />
              </div>
            )
          })}

          <hr />
          <h6>Other Bookings&apos; pending actions:</h6>
          <div>
            <b>{otherBookingPendingActions?.length === 0 && 'No pending actions'}</b>
          </div>
          {otherBookingPendingActions?.map(
            (otherBookingActionItem, otherBookingActionItemIndex) => {
              return (
                <div
                  key={otherBookingActionItemIndex}
                  style={{ border: '1px solid #CECECE', borderRadius: '5px', marginBottom: '5px' }}
                >
                  <RenderAction
                    actionItem={otherBookingActionItem}
                    actionItemIndex={otherBookingActionItemIndex}
                    refreshPendingActions={refreshPendingActions}
                    patientId={patientId}
                    clinicId={clinicId}
                    eventIdForNextBooking={eventDataForNextVisit?.uid}
                    eventDataForNextBooking={eventDataForNextVisit}
                    scheduleForThisEventNextBooking={scheduleForNextVisit}
                    professionalDataForNextVisit={professionalDataForNextVisit}
                    allProfessionalsDataForAssistant={allProfessionalsDataForAssistant}
                    selectedClinic={selectedClinic}
                  />
                </div>
              )
            },
          )}
        </div>
      </BootstrapDialog>
    </>
  )
}

export const PatientForm = ({
  newPatient,
  patientId,
  showSeparateDialogActions,
  clinicId,
  clinicBrandId,
  mentorProfileData,
}) => {
  const [patientDetailsLocal, setPatientDetailsLocal] = useState({ ...BLANK_PATIENT_DATA })
  const [loading, setLoading] = useState(false)
  const [editMode, setEditMode] = useState(false)

  async function getAndSetExistingPatientDetails() {
    setLoading(true)
    const response = await getCPDataWithoutParams(
      `/api/secure/patient/list?patientId=${patientId}&clinicId=${clinicId}`,
    )
    if (response.status === 200 && response?.data && response?.data?.[0]) {
      setLoading(false)
      const receivedPatientData = response.data[0]
      let age =
        receivedPatientData?.dateOfBirth && getAgeFromDobWithTime(receivedPatientData?.dateOfBirth)
      setPatientDetailsLocal((prev) => {
        return {
          ...prev,
          name: receivedPatientData?.name ?? prev.name,
          email: receivedPatientData?.email ?? prev.email,
          mobile:
            removePlusFromBeginning(receivedPatientData?.mobile) ??
            removePlusFromBeginning(prev.mobile),
          gender: receivedPatientData?.gender ?? prev.gender,
          age: age ?? prev.age,
          note: receivedPatientData?.note ?? prev.note,
          referredBy: receivedPatientData?.referredBy ?? prev.referredBy,
          address: receivedPatientData?.location?.address ?? prev.address,
          pincode: receivedPatientData?.location?.pincode ?? prev.pincode,
          city: receivedPatientData?.location?.city ?? prev.city,
        }
      })
    } else {
      toast.error("Couldn't find patient details")
    }
  }

  async function handleEditPatientDetails() {
    const dataToSubmit = {
      apiParam: 'PatientInfo',
      id: patientId,
      clinicId: clinicId,
      clinicBrandId: clinicBrandId,
      mentorId: mentorProfileData?.id,
      name: patientDetailsLocal?.name,
      email: patientDetailsLocal?.email,
      mobile: patientDetailsLocal?.mobile && `+${patientDetailsLocal?.mobile}`,
      dateOfBirth: getDOBWithTime(patientDetailsLocal?.age),
      gender: patientDetailsLocal?.gender,
      note: patientDetailsLocal?.note,
      referredBy: patientDetailsLocal?.referredBy,
      location: {
        address: patientDetailsLocal?.address,
        pincode: patientDetailsLocal?.pincode,
        city: patientDetailsLocal?.city,
      },
    }
    setLoading(true)
    let response = await postCPDataWithoutParams('/api/secure/patient/save', dataToSubmit)
    if (response.status === 200) {
      toast.success('Patient Details updated')
    } else {
      toast.error('Error creating new patient')
    }
    setLoading(false)
  }

  async function setup() {
    getAndSetExistingPatientDetails()
  }

  useEffect(() => {
    setup()
  }, [])

  return (
    <>
      <DialogContent dividers>
        <PIParent>
          <PISection>
            {/* Name */}
            <PISectionItem>
              <PISectionTitle>Name*</PISectionTitle>
              <PISectionInput
                disabled={!editMode}
                type="text"
                value={patientDetailsLocal?.name}
                onChange={(e) =>
                  setPatientDetailsLocal((prev) => ({ ...prev, name: e.target.value }))
                }
              />
            </PISectionItem>
            {/* Gender */}
            <PISectionItem>
              <PISectionTitle>Gender*</PISectionTitle>
              <RectangleRadioButton
                disabled={!editMode}
                options={[
                  { label: 'Male', id: 'male' },
                  { label: 'Female', id: 'female' },
                  { label: 'Other', id: 'nonBinary' },
                ]}
                selectedId={patientDetailsLocal?.gender}
                selectionFunction={(id) =>
                  setPatientDetailsLocal((prev) => ({ ...prev, gender: id }))
                }
              />
            </PISectionItem>
            {/* Age */}
            <PISectionItem>
              <PISectionTitle>Age*</PISectionTitle>
              <PISectionInput
                disabled={!editMode}
                type="number"
                value={patientDetailsLocal?.age}
                onChange={(e) =>
                  setPatientDetailsLocal((prev) => ({ ...prev, age: e.target.value }))
                }
              />
            </PISectionItem>
            {/* Mobile */}
            <PISectionItem>
              <PISectionTitle>Mobile*</PISectionTitle>
              <EDVCPhoneInput
                disabled={!editMode}
                specialLabel=""
                placeholder="Mobile Number"
                country={localStorage.getItem('countryCode')?.toLowerCase() || 'in'}
                value={patientDetailsLocal?.mobile}
                onChange={(value) => setPatientDetailsLocal((prev) => ({ ...prev, mobile: value }))}
              />
            </PISectionItem>
          </PISection>
          <PISection>
            {/* Email */}
            <PISectionItem>
              <PISectionTitle>Email</PISectionTitle>
              <PISectionInput
                type="text"
                disabled={!editMode}
                value={patientDetailsLocal?.email}
                onChange={(e) =>
                  setPatientDetailsLocal((prev) => ({ ...prev, email: e.target.value.trim() }))
                }
              />
            </PISectionItem>
            {/* Address */}
            <PISectionItem>
              <PISectionTitle>Address</PISectionTitle>
              <PISectionInput
                type="text"
                disabled={!editMode}
                value={patientDetailsLocal?.address}
                onChange={(e) =>
                  setPatientDetailsLocal((prev) => ({ ...prev, address: e.target.value }))
                }
              />
            </PISectionItem>
            {/* City */}
            <PISectionItem>
              <PISectionTitle>City</PISectionTitle>
              <PISectionInput
                type="text"
                disabled={!editMode}
                value={patientDetailsLocal?.city}
                onChange={(e) =>
                  setPatientDetailsLocal((prev) => ({ ...prev, city: e.target.value }))
                }
              />
            </PISectionItem>
            {/* Pincode */}
            <PISectionItem>
              <PISectionTitle>Pincode</PISectionTitle>
              <PISectionInput
                type="number"
                disabled={!editMode}
                value={patientDetailsLocal?.pincode}
                onChange={(e) =>
                  setPatientDetailsLocal((prev) => ({ ...prev, pincode: e.target.value }))
                }
              />
            </PISectionItem>
            {/* Note */}
            <PISectionItem>
              <PISectionTitle>Note</PISectionTitle>
              <PISectionInput
                type="text"
                disabled={!editMode}
                value={patientDetailsLocal?.note}
                onChange={(e) =>
                  setPatientDetailsLocal((prev) => ({ ...prev, note: e.target.value }))
                }
              />
            </PISectionItem>
            {/* Referred By */}
            <PISectionItem>
              <PISectionTitle>Referred By</PISectionTitle>
              <PISectionInput
                type="text"
                disabled={!editMode}
                value={patientDetailsLocal?.referredBy}
                onChange={(e) =>
                  setPatientDetailsLocal((prev) => ({ ...prev, referredBy: e.target.value }))
                }
              />
            </PISectionItem>
          </PISection>
        </PIParent>
      </DialogContent>

      {showSeparateDialogActions && (
        <DialogActions>
          {!newPatient && (
            <MuiButton autoFocus onClick={() => setEditMode((prev) => !prev)}>
              {editMode ? 'View Mode' : 'Edit'}
            </MuiButton>
          )}
          {!newPatient && editMode && (
            <MuiButton
              color="success"
              autoFocus
              onClick={handleEditPatientDetails}
              disabled={
                !(
                  Boolean(patientDetailsLocal?.name) &&
                  (validateEmail(patientDetailsLocal?.email) ||
                    isValidPhoneNumber(`+${patientDetailsLocal?.mobile}`)) &&
                  Boolean(patientDetailsLocal?.gender) &&
                  Boolean(patientDetailsLocal?.age)
                ) || loading
              }
            >
              Save changes
            </MuiButton>
          )}
        </DialogActions>
      )}
    </>
  )
}

export const RectangleRadioButton = ({ options, selectedId, selectionFunction, disabled }) => {
  return (
    <RRBContainer>
      {options?.map((option) => (
        <RRBItem
          key={option?.id}
          selected={option?.id === selectedId}
          onClick={() => {
            if (!disabled) {
              selectionFunction(option?.id)
            }
          }}
        >
          {option?.label}
        </RRBItem>
      ))}
    </RRBContainer>
  )
}

export const RenderAction = ({
  actionItem,
  actionItemIndex,
  refreshPendingActions,

  patientId,
  clinicId,
  eventIdForNextBooking,
  eventDataForNextBooking,
  scheduleForThisEventNextBooking,
  professionalDataForNextVisit,

  allProfessionalsDataForAssistant,
  selectedClinic,
}) => {
  if (actionItem?.type === 'refund') {
    return (
      <PaymentAction
        actionItem={actionItem}
        actionItemIndex={actionItemIndex}
        refreshPendingActions={refreshPendingActions}
      />
    )
  } else if (actionItem?.type === 'schedule-next-visit') {
    return (
      <NextVisitAction
        actionItem={actionItem}
        actionItemIndex={actionItemIndex}
        refreshPendingActions={refreshPendingActions}
        patientId={patientId}
        clinicId={clinicId}
        eventIdForNextBooking={eventIdForNextBooking}
        eventDataForNextBooking={eventDataForNextBooking}
        scheduleForThisEventNextBooking={scheduleForThisEventNextBooking}
        professionalDataForNextVisit={professionalDataForNextVisit}
      />
    )
  } else if (actionItem?.type === 'schedule-therapy') {
    return (
      <ScheduleTherapyAction
        actionItem={actionItem}
        actionItemIndex={actionItemIndex}
        refreshPendingActions={refreshPendingActions}
        allProfessionalsDataForAssistant={allProfessionalsDataForAssistant}
        clinicId={clinicId}
        patientId={patientId}
        selectedClinic={selectedClinic}
      />
    )
  }
}

export const PaymentAction = ({ actionItem, actionItemIndex, refreshPendingActions }) => {
  const orignalPaidFee = actionItem?.actionQData?.data?.originalPrice
  const newFee = actionItem?.actionQData?.data?.discountedPrice
  const differenceAmountPaid = newFee - orignalPaidFee
  const [submitLoading, setSubmitLoading] = useState(false)

  const [apiSubmissionBody, setApiSubmissionBody] = useState({
    paymentMode: 'cash',
    currency: 'inr',
    feePaid: differenceAmountPaid,
    feePaymentMode: 'cash',
    feePaidTxnId: '',
    cashBreakUp: { 500: 0, 200: 0, 100: 0, 50: 0, 20: 0, 10: 0, 5: 0, 2: 0, 1: 0 },
  })

  async function handlePaymentActionCompleted() {
    setSubmitLoading(true)
    await putCPDataWithoutParams(
      `/api/secure/clinic-assistant/booked-event/discount-refund-complete?actionQId=${actionItem?.id}`,
      removeInvalidValuesCash(apiSubmissionBody),
    )
    await refreshPendingActions()
    setSubmitLoading(false)
  }

  const total =
    apiSubmissionBody?.cashBreakUp?.['500'] * 500 +
    apiSubmissionBody?.cashBreakUp?.['200'] * 200 +
    apiSubmissionBody?.cashBreakUp?.['100'] * 100 +
    apiSubmissionBody?.cashBreakUp?.['50'] * 50 +
    apiSubmissionBody?.cashBreakUp?.['20'] * 20 +
    apiSubmissionBody?.cashBreakUp?.['10'] * 10 +
    apiSubmissionBody?.cashBreakUp?.['5'] * 5 +
    apiSubmissionBody?.cashBreakUp?.['2'] * 2 +
    apiSubmissionBody?.cashBreakUp?.['1'] * 1

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px' }}>
      <div>
        <b>{actionItemIndex + 1}. Payment Action</b>
      </div>
      <div>
        Fee Paid: {actionItem?.actionQData?.data?.originalPrice} / New Fee:{' '}
        {actionItem?.actionQData?.data?.discountedPrice}
      </div>
      <div>
        <u>
          <span>{differenceAmountPaid < 0 ? 'Return Amount: ' : 'Collect Amount: '}</span>
          <span>{differenceAmountPaid < 0 ? differenceAmountPaid * -1 : differenceAmountPaid}</span>
        </u>
      </div>
      <div>
        <span>Payment Mode:</span>
        <span>
          <EDVCBookNewSlotSelect
            style={{ maxWidth: '200px', marginLeft: '5px', padding: '3px' }}
            value={apiSubmissionBody?.feePaymentMode}
            onChange={(e) =>
              setApiSubmissionBody((prev) => ({
                ...prev,
                paymentMode: e.target.value,
                feePaymentMode: e.target.value,
              }))
            }
          >
            {OFFLINE_PAYMENT_METHODS_LIST?.map((paymentMethod, paymentMethodIndex) => (
              <option value={paymentMethod.id} key={paymentMethodIndex}>
                {paymentMethod.title}
              </option>
            ))}
          </EDVCBookNewSlotSelect>
        </span>
      </div>
      {apiSubmissionBody?.feePaymentMode === 'cash' && (
        <div>
          <div>Enter Denomination: </div>

          {['500', '200', '100', '50', '20', '10', '5', '2', '1'].map((denomination) =>
            renderDenominationInput(
              denomination,
              apiSubmissionBody?.cashBreakUp?.[denomination],
              (newValue) =>
                setApiSubmissionBody((prev) => ({
                  ...prev,
                  cashBreakUp: { ...prev?.cashBreakUp, [denomination]: newValue },
                })),
            ),
          )}

          <GenericDisplayFlex>
            <CashDenominationText>Total: </CashDenominationText>
            <span
              style={{
                fontWeight: '500',
                color: `${
                  total === differenceAmountPaid || total === differenceAmountPaid * -1
                    ? 'green'
                    : 'red'
                }`,
              }}
            >
              {Boolean(total) ? total : ''}
            </span>
          </GenericDisplayFlex>
        </div>
      )}
      {apiSubmissionBody?.feePaymentMode !== 'cash' && (
        <div>
          <span>Transaction ID: </span>
          <CashDenominationInput
            style={{ width: '200px' }}
            value={apiSubmissionBody?.feePaidTxnId}
            onChange={(e) =>
              setApiSubmissionBody((prev) => ({ ...prev, feePaidTxnId: e.target.value }))
            }
          />
        </div>
      )}
      {submitLoading ? (
        <Spinner animation="border" variant="dark" size="sm" />
      ) : (
        <div style={{ display: 'flex', gap: '10px' }}>
          {/* <EDVCRectangleButton>Cancel</EDVCRectangleButton> */}
          <EDVCRectangleButton action onClick={handlePaymentActionCompleted}>
            Mark Action Completed
          </EDVCRectangleButton>
        </div>
      )}
    </div>
  )
}

export const NextVisitAction = ({
  actionItem,
  actionItemIndex,
  refreshPendingActions,
  patientId,
  clinicId,
  eventIdForNextBooking,
  eventDataForNextBooking,
  scheduleForThisEventNextBooking,
  professionalDataForNextVisit,
}) => {
  const [bookingSlotSelected, setBookingSlotSelected] = useState()
  const [planDataForSelectedEventId, setPlanDataForSelectedEventId] = useState()
  const [paymentDetail, setPaymentDetail] = useState({
    feePaid: undefined,
    feePaymentMode: 'cash',
    feePaidTxnId: '',
  })
  const [bookingLoading, setBookingLoading] = useState(false)

  useEffect(() => {
    ;(async () => {
      const eventDataForSelectedEventType = professionalDataForNextVisit?.eventTypes?.find(
        (eventType) => eventType?.uid === eventIdForNextBooking,
      )
      if (eventDataForSelectedEventType && !eventDataForSelectedEventType?.free) {
        const response = await getSubscriptionPlanById(
          eventDataForSelectedEventType?.subscriptionPlanId,
        )
        if (response.status === 200 && response?.data) {
          setPlanDataForSelectedEventId(response?.data)
        }
      } else {
        setPlanDataForSelectedEventId(null)
      }
    })()
  }, [])

  const askPayment = Boolean(
    Boolean(planDataForSelectedEventId?.finalPrice) && !eventDataForNextBooking?.free,
  )

  return (
    <div style={{ padding: '10px' }}>
      <div>
        <b>{actionItemIndex + 1}. Next Visit Action</b>
      </div>
      <div style={{ marginBottom: '20px' }}>
        Suggested Next Visit: {moment(actionItem?.actionQData?.nextVisitDate)?.format('DD-MM-YYYY')}
      </div>
      <GenericEventBookingComponent
        key={eventIdForNextBooking}
        eventId={eventIdForNextBooking}
        scheduleForThisEvent={scheduleForThisEventNextBooking}
        professionalData={professionalDataForNextVisit}
        getSelectionDetailFunction={(data) => setBookingSlotSelected(data)}
      />
      {bookingSlotSelected && (
        <div style={{ marginTop: '30px' }}>
          {askPayment && (
            <div>
              <div>Default Fee: {planDataForSelectedEventId?.finalPrice}</div>
              <>
                <div style={{ marginTop: '5px' }}>Fee Collected</div>
                <PISectionInput
                  value={paymentDetail?.feePaid}
                  onChange={(e) =>
                    setPaymentDetail((prev) => ({ ...prev, feePaid: e.target.value }))
                  }
                />
              </>
              <>
                <div style={{ marginTop: '5px' }}>Payment Mode</div>
                <EDVCBookNewSlotSelect
                  value={paymentDetail?.feePaymentMode}
                  onChange={(e) =>
                    setPaymentDetail((prev) => ({ ...prev, feePaymentMode: e.target.value }))
                  }
                >
                  {OFFLINE_PAYMENT_METHODS_LIST?.map((paymentMethod, paymentMethodIndex) => (
                    <option value={paymentMethod.id} key={paymentMethodIndex}>
                      {paymentMethod.title}
                    </option>
                  ))}
                </EDVCBookNewSlotSelect>
              </>
              {paymentDetail?.feePaymentMode !== 'cash' && (
                <>
                  <div style={{ marginTop: '5px' }}>Transaction ID</div>
                  <PISectionInput
                    value={paymentDetail?.feePaidTxnId}
                    onChange={(e) =>
                      setPaymentDetail((prev) => ({ ...prev, feePaidTxnId: e.target.value }))
                    }
                  />
                </>
              )}
            </div>
          )}

          {bookingLoading ? (
            <Spinner animation="border" variant="dark" size="md" />
          ) : (
            <MuiButton
              sx={{ marginTop: '10px', display: 'block' }}
              variant="contained"
              disabled={(() => {
                if (askPayment) {
                  if (paymentDetail?.feePaid && paymentDetail?.feePaymentMode) {
                    if (paymentDetail?.feePaymentMode === 'cash') {
                      return false
                    } else if (
                      paymentDetail?.feePaymentMode !== 'cash' &&
                      paymentDetail?.feePaidTxnId
                    ) {
                      return false
                    } else {
                      return true
                    }
                  } else {
                    return true
                  }
                } else {
                  return false
                }
              })()}
              onClick={() =>
                makeBookingAssistant(
                  askPayment,
                  professionalDataForNextVisit,
                  bookingSlotSelected,
                  eventDataForNextBooking,
                  patientId,
                  clinicId,
                  actionItem,
                  paymentDetail,
                  setBookingLoading,
                  refreshPendingActions,
                )
              }
            >
              Book Now
            </MuiButton>
          )}
        </div>
      )}
    </div>
  )
}

export const ScheduleTherapyAction = ({
  actionItem,
  actionItemIndex,
  refreshPendingActions,

  allProfessionalsDataForAssistant,
  clinicId,
  patientId,
  selectedClinic,
}) => {
  const [eventTypesVisible, setEventTypesVisible] = useState([])
  const [selectedEventTypeId, setSelectedEventTypeId] = useState()

  const [bookingSlotSelected, setBookingSlotSelected] = useState()
  const [planDataForSelectedEventId, setPlanDataForSelectedEventId] = useState()
  const [paymentDetail, setPaymentDetail] = useState({
    feePaid: undefined,
    feePaymentMode: 'cash',
    feePaidTxnId: '',
  })
  const [bookingLoading, setBookingLoading] = useState(false)

  const thisProfessionalData = allProfessionalsDataForAssistant?.find(
    (item) => item?.id === actionItem?.actionQData?.requestedTherapist?.id,
  )

  useEffect(() => {
    const arrayToInsert = []
    thisProfessionalData?.eventTypes?.forEach((eventTypeItem) => {
      if (eventTypeItem?.contextId === clinicId) {
        arrayToInsert.push(eventTypeItem)
      }
    })
    setEventTypesVisible(arrayToInsert)
    setSelectedEventTypeId(arrayToInsert?.[0]?.uid)
  }, [thisProfessionalData])

  const eventDataForSelectedEventType = eventTypesVisible?.find(
    (eventType) => eventType?.uid === selectedEventTypeId,
  )

  const scheduleForSelectedEventType = useMemo(() => {
    if (eventDataForSelectedEventType?.contextId === clinicId) {
      if (
        thisProfessionalData?.schedules?.some((schedule) =>
          schedule?.scopeSchedules?.includes(clinicId),
        )
      ) {
        const arrayOfAllSchedulesAssoicatedWithThisClinic = thisProfessionalData?.schedules?.filter(
          (schedule) => schedule?.scopeSchedules?.includes(clinicId),
        )
        const clinicAssociatedScheduleWithThisEventId =
          arrayOfAllSchedulesAssoicatedWithThisClinic?.find((schedule) =>
            schedule?.eventIds?.includes(clinicId),
          )
        if (clinicAssociatedScheduleWithThisEventId) {
          return clinicAssociatedScheduleWithThisEventId
        } else if (arrayOfAllSchedulesAssoicatedWithThisClinic?.length > 0) {
          return arrayOfAllSchedulesAssoicatedWithThisClinic?.[0]
        }
      } else {
        const defaultClinicSchedule = getDefaultScheduleFromSchedules(selectedClinic?.schedules)
        if (defaultClinicSchedule) {
          return defaultClinicSchedule
        }
      }
    } else {
      const scheduleWithRequestedEventId = thisProfessionalData?.schedules?.find((schedule) =>
        schedule?.eventIds?.includes(selectedEventTypeId),
      )
      if (scheduleWithRequestedEventId) {
        return scheduleWithRequestedEventId
      }
    }
  }, [thisProfessionalData, selectedEventTypeId])

  useEffect(() => {
    ;(async () => {
      const eventDataForSelectedEventType = thisProfessionalData?.eventTypes?.find(
        (eventType) => eventType?.uid === selectedEventTypeId,
      )
      if (eventDataForSelectedEventType && !eventDataForSelectedEventType?.free) {
        const response = await getSubscriptionPlanById(
          eventDataForSelectedEventType?.subscriptionPlanId,
        )
        if (response.status === 200) {
          setPlanDataForSelectedEventId(response?.data)
        }
      } else {
        setPlanDataForSelectedEventId(null)
      }
    })()
  }, [selectedEventTypeId])

  const askPayment = Boolean(
    Boolean(planDataForSelectedEventId?.finalPrice) && !eventDataForSelectedEventType?.free,
  )

  if (Boolean(thisProfessionalData)) {
    return (
      <div style={{ padding: '10px' }}>
        <div>
          <b>{actionItemIndex + 1}. Schedule Therapy Action</b>
        </div>
        <div style={{ marginTop: '20px' }}>With {thisProfessionalData?.name}</div>
        <div>Choose Event:</div>
        <EDVCEventsContainer>
          {eventTypesVisible?.map((eventTypeItem, eventTypeIndex) => {
            return (
              <NewEventTypeButton
                key={eventTypeIndex}
                selected={selectedEventTypeId === eventTypeItem?.uid}
                onClick={() => setSelectedEventTypeId(eventTypeItem?.uid)}
              >
                {eventTypeItem?.title}
              </NewEventTypeButton>
            )
          })}
        </EDVCEventsContainer>
        <GenericEventBookingComponent
          key={selectedEventTypeId}
          eventId={selectedEventTypeId}
          scheduleForThisEvent={scheduleForSelectedEventType}
          professionalData={thisProfessionalData}
          getSelectionDetailFunction={(data) => setBookingSlotSelected(data)}
        />
        {bookingSlotSelected && (
          <div style={{ marginTop: '30px' }}>
            {askPayment && (
              <div>
                <div>Default Fee: {planDataForSelectedEventId?.finalPrice}</div>
                <>
                  <div style={{ marginTop: '5px' }}>Fee Collected</div>
                  <PISectionInput
                    value={paymentDetail?.feePaid}
                    onChange={(e) =>
                      setPaymentDetail((prev) => ({ ...prev, feePaid: e.target.value }))
                    }
                  />
                </>
                <>
                  <div style={{ marginTop: '5px' }}>Payment Mode</div>
                  <EDVCBookNewSlotSelect
                    value={paymentDetail?.feePaymentMode}
                    onChange={(e) =>
                      setPaymentDetail((prev) => ({ ...prev, feePaymentMode: e.target.value }))
                    }
                  >
                    {OFFLINE_PAYMENT_METHODS_LIST?.map((paymentMethod, paymentMethodIndex) => (
                      <option value={paymentMethod.id} key={paymentMethodIndex}>
                        {paymentMethod.title}
                      </option>
                    ))}
                  </EDVCBookNewSlotSelect>
                </>
                {paymentDetail?.feePaymentMode !== 'cash' && (
                  <>
                    <div style={{ marginTop: '5px' }}>Transaction ID</div>
                    <PISectionInput
                      value={paymentDetail?.feePaidTxnId}
                      onChange={(e) =>
                        setPaymentDetail((prev) => ({ ...prev, feePaidTxnId: e.target.value }))
                      }
                    />
                  </>
                )}
              </div>
            )}
            {bookingLoading ? (
              <Spinner animation="border" variant="dark" size="md" />
            ) : (
              <MuiButton
                sx={{ marginTop: '10px', display: 'block' }}
                variant="contained"
                disabled={(() => {
                  if (askPayment) {
                    if (paymentDetail?.feePaid && paymentDetail?.feePaymentMode) {
                      if (paymentDetail?.feePaymentMode === 'cash') {
                        return false
                      } else if (
                        paymentDetail?.feePaymentMode !== 'cash' &&
                        paymentDetail?.feePaidTxnId
                      ) {
                        return false
                      } else {
                        return true
                      }
                    } else {
                      return true
                    }
                  } else {
                    return false
                  }
                })()}
                onClick={() =>
                  makeBookingAssistant(
                    askPayment,
                    thisProfessionalData,
                    bookingSlotSelected,
                    eventDataForSelectedEventType,
                    patientId,
                    clinicId,
                    actionItem,
                    paymentDetail,
                    setBookingLoading,
                    refreshPendingActions,
                  )
                }
              >
                Book Now
              </MuiButton>
            )}
          </div>
        )}
      </div>
    )
  } else {
    return (
      <div style={{ padding: '10px' }}>
        <div>
          <b>{actionItemIndex + 1}. Schedule Therapy Action</b>
        </div>
        <div>Error: No Mentor Data</div>
      </div>
    )
  }
}

export const renderDenominationInput = (denomination, value, onChange) => {
  return (
    <GenericDisplayFlex>
      <CashDenominationText>{`${parseInt(denomination)} x `}</CashDenominationText>
      <CashDenominationInput
        type="number"
        value={value}
        onChange={(e) => onChange(parseInt(e.target.value))}
      />
      <span>
        {' '}
        = {Boolean(value * parseFloat(denomination)) ? value * parseFloat(denomination) : ''}
      </span>
    </GenericDisplayFlex>
  )
}

const MoreOptionsMenuForStrip = ({
  handleNewTherapyRequest,
  therapyBookingDataForThisEvent,
  bookedSlot,
  setClickedBookedSlotData,
  setEditPatientDialogOpen,
  forMentor,
}) => {
  const [moreOptionsAnchor, setMoreOptionsAnchor] = useState(null)
  const open = Boolean(moreOptionsAnchor)
  const handleClick = (event) => {
    event.stopPropagation()
    setMoreOptionsAnchor(event.currentTarget)
  }
  const handleClose = () => {
    setMoreOptionsAnchor(null)
  }

  async function bookScreeningForThisBooking() {
    const response = await putCPDataWithoutParams(`/api/secure/meeting/screening/book`, {
      ...bookedSlot,
      apiParam: 'booked-event-info',
    })
    if (
      response?.status === 200 &&
      isCommonResponseSuccessful(response?.data?.code) &&
      response?.data?.data?.screening
    ) {
      toast.success(
        `Screening booked for ${response?.data?.data?.screening?.userName} with ${response?.data?.data?.screening?.mentorName}`,
      )
    } else {
      toast.error('Error booking screening')
    }
  }

  return (
    <div>
      <MoreVertIcon
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      />
      <Menu
        id="basic-menu"
        anchorEl={moreOptionsAnchor}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {(!Boolean(bookedSlot?.previousSteps) || bookedSlot?.previousSteps?.length === 0) && (
          <MenuItem
            onClick={() => {
              bookScreeningForThisBooking()
              handleClose()
            }}
          >
            Book Screening
          </MenuItem>
        )}
        {!forMentor && (
          <MenuItem
            onClick={() => {
              handleNewTherapyRequest(therapyBookingDataForThisEvent, bookedSlot)
              handleClose()
            }}
          >
            Book Therapy
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            setClickedBookedSlotData(bookedSlot)
            setEditPatientDialogOpen(true)
            handleClose()
          }}
        >
          Patient Details
        </MenuItem>
      </Menu>
    </div>
  )
}

// helpers :

async function makeBookingInDayViewCalendar(
  forMentor,
  clinicId,
  clinicBrandId,
  teacherId,
  askPayment = false,
  timingData,
  appointmentNumber,
  eventDetails,
  patientDetails,
  getAndSetBookedSlotsOfSelectedDate,
  setNewBookingDialogOpen,
  offlinePaymentDetails,
  setBookingLoading,
  setSelectedPatient,
  setIsNewPatient,
  setNewPatientInputData,
  chosenChamberRoomId,
  tryBookingScreening,
  holdSlotAndSendPaymentLink,
  reasonDataForNotDoingScreening,
) {
  let paymentGateway
  if (askPayment) {
    if (holdSlotAndSendPaymentLink) {
      paymentGateway = 'paymentLinkInitiatedByMentor'
    } else {
      paymentGateway = 'paidOffline'
    }
  } else {
    paymentGateway = 'free'
  }

  const clientTransactionId = uuidv4()

  const dataToSubmit = {
    apiParam: 'booked-event-info',
    mentorId: teacherId,
    userId: '',
    startTime: moment?.utc(timingData?.startTime)?.format('YYYY-MM-DD HH:mm:ss'),
    endTime: moment?.utc(timingData?.endTime)?.format('YYYY-MM-DD HH:mm:ss'),
    eventType: eventDetails?.type,
    chamberRoomId: chosenChamberRoomId,
    eventTypeId: eventDetails?.uid,
    subscriptionPlanId: eventDetails?.subscriptionPlanId,
    patientId: patientDetails?.id,
    id: '',
    appointmentNumber: appointmentNumber,
    feePaid: askPayment ? offlinePaymentDetails?.feePaid : '',
    currency: offlinePaymentDetails?.currency || 'inr',
    feePaymentMode: askPayment ? offlinePaymentDetails?.feePaymentMode : '',
    feePaidTxnId: askPayment ? offlinePaymentDetails?.feePaidTxnId : '',
    clinicId: clinicId || '',
    clinicBrandId: clinicBrandId || '',
    bookingTime: moment()?.format(MOMENT_FORMATS.javaInstantFormat),
    screeningRequest: {
      requested: tryBookingScreening,
      reasonId: reasonDataForNotDoingScreening?.id || '',
      reason: reasonDataForNotDoingScreening?.title || '',
    },
  }

  setBookingLoading(true)
  let response
  // distinction between clinic assistant api and mentor api
  if (forMentor) {
    response = await putCPDataWithoutParams(
      `/api/secure/meeting/book?teacherId=${teacherId}&paymentGateway=${paymentGateway}&clientTransactionId=${clientTransactionId}&platform=${platformWeb}&paymentMethod=upi&cancelUrl=${encodeURIComponent(
        window.location.href,
      )}&successUrl=${encodeURIComponent(window.location.href)}`,
      dataToSubmit,
    )
  } else {
    response = await putCPDataWithoutParams(
      `/api/secure/meeting/book?teacherId=${teacherId}&paymentGateway=${paymentGateway}&clientTransactionId=${clientTransactionId}&platform=${platformWeb}&paymentMethod=upi&cancelUrl=${encodeURIComponent(
        window.location.href,
      )}&successUrl=${encodeURIComponent(
        window.location.href,
      )}&bookScreeningAutomatically=${tryBookingScreening}`,
      dataToSubmit,
    )
  }
  setBookingLoading(false)
  if (response?.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
    toast.success('Booked session')
    setSelectedPatient(null)
    setIsNewPatient(false)
    setNewPatientInputData({ ...BLANK_PATIENT_DATA })
    getAndSetBookedSlotsOfSelectedDate()
    setNewBookingDialogOpen(false)
    if (response?.data?.data?.userPaymentLink?.paymentLink) {
      navigator.clipboard.writeText(response?.data?.data?.userPaymentLink?.paymentLink)
      toast.info('Payment Link sent to client')
      toast.info('Payment Link copied to clipboard')
    }
  } else {
    toast.error('Error Booking Session')
  }
}

async function createNewPatient(
  clinicId,
  clinicBrandId,
  mentorProfileData,
  newPatientInputData,
  setNewPatientSubmissionLoading,
  forMentor,
  setSelectedPatient,
  setNewPatientInputData,
  setIsNewPatient,
  setRelatedToPatient,
  setRelatedPatientLoading,
) {
  let dataToSubmit = {
    apiParam: 'PatientInfo',
    clinicId: clinicId,
    clinicBrandId: clinicBrandId,
    mentorId: mentorProfileData?.id,
    name: newPatientInputData?.name,
    email: newPatientInputData?.email,
    mobile: newPatientInputData?.mobile ? `+${newPatientInputData?.mobile}` : null,
    dateOfBirth: getDOBWithTime(newPatientInputData?.age),
    gender: newPatientInputData?.gender,
    note: newPatientInputData?.note,
    referredBy: newPatientInputData?.referredBy,
  }
  let addAddToRelatedPatientStateAfterCreation = null
  if (
    newPatientInputData?.dependentRelation &&
    newPatientInputData?.relatedToExistingPatient &&
    newPatientInputData?.relatedToExistingPatient?.id
  ) {
    dataToSubmit = {
      ...dataToSubmit,
      primaryPatientProfile: {
        apiParam: 'primary-patient-profile',
        patientId: newPatientInputData?.relatedToExistingPatient?.id,
        mobile: newPatientInputData?.relatedToExistingPatient?.mobile,
        email: newPatientInputData?.relatedToExistingPatient?.email,
        userId: newPatientInputData?.relatedToExistingPatient?.userId,
        dependentRelation: newPatientInputData?.dependentRelation,
      },
    }
    addAddToRelatedPatientStateAfterCreation = newPatientInputData?.relatedToExistingPatient?.id
  }
  setNewPatientSubmissionLoading(true)
  // distinction between clinic assistant api and mentor api
  let response
  if (forMentor) {
    response = await postCPDataWithoutParams('/api/secure/patient/new', dataToSubmit)
  } else {
    response = await postCPDataWithoutParams('/api/secure/patient/new', dataToSubmit)
  }
  if (response.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
    setSelectedPatient(response?.data?.data?.patient)
    setNewPatientInputData(BLANK_PATIENT_DATA)
    setIsNewPatient(false)
    if (addAddToRelatedPatientStateAfterCreation) {
      setRelatedToPatient(null)
      setRelatedPatientLoading(true)
      const responseTwo = await getCPDataWithoutParams(
        `/api/secure/patient/list?patientId=${addAddToRelatedPatientStateAfterCreation}&clinicId=${clinicId}`,
      )
      setRelatedPatientLoading(false)
      if (responseTwo.status === 200 && responseTwo?.data && responseTwo?.data?.[0]) {
        setRelatedToPatient(responseTwo?.data?.[0])
      } else {
        toast.error('Error finding related patient data. Contact support.')
      }
    }
  } else {
    toast.error('Error creating new patient')
  }
  setNewPatientSubmissionLoading(false)
}

export async function loadOptionsForExistingPatient(
  search,
  loadedOptions,
  { page, clinicId, clinicBrandId, setAutoFillStuffForNewPatientString },
) {
  setAutoFillStuffForNewPatientString(search)

  // distinction between clinic assistant api and mentor api
  const response = await axios.get(
    `${clinicApiURL}/api/secure/patient/list?search=${search}&clinicId=${clinicId}&clinicBrandId=${clinicBrandId}&page=${page}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        ...commonHeadersCPWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
    },
  )
  var hasMore = true
  var optionsArray
  if (response?.status === 200) {
    optionsArray = response?.data?.map((option, index) => {
      return {
        ...option,
        id: option?.id,
        name: option?.name || option?.firstName,
      }
    })
  }
  if (response?.status !== 200 || !optionsArray?.length) {
    hasMore = false
  }
  return {
    options: optionsArray,
    hasMore,
    additional: {
      page: page + 1,
      clinicId,
      setAutoFillStuffForNewPatientString,
    },
  }
}

export function getLabelForAlreadyPatientOption(option) {
  let label = option.name
  if (option?.dateOfBirth || option?.gender) {
    label += ' ('
  }
  if (option?.dateOfBirth) {
    label += getAgeFromDobWithTime(option?.dateOfBirth)
  }
  if (option?.dateOfBirth && option?.gender) {
    label += '/'
  }
  if (option?.gender) {
    label += GENDER_LIST_OFFLINE?.find((item) => item.value === option?.gender)?.label || '?'
  }
  if (option?.dateOfBirth || option?.gender) {
    label += ')'
  }
  if (option?.mobile) {
    label += `, ${option?.mobile}`
  }
  if (!option?.mobile && option?.email) {
    label += `, ${option?.email}`
  }
  return label
}

function identifyStringUseForAutoFill(inputString) {
  if (inputString && inputString.length > 0) {
    if (validateEmail(inputString)) {
      return { isValid: true, type: 'email' }
    } else if (validateOnlyNumbers(inputString)) {
      return { isValid: true, type: 'mobile' }
    } else if (validateName(inputString)) {
      return { isValid: true, type: 'name' }
    } else {
      return { isValid: false, type: '' }
    }
  } else {
    return { isValid: false, type: '' }
  }
}

async function handleUpdatePatientDetails(
  patientId,
  clinicId,
  clinicBrandId,
  mentorProfileData,
  patientDetailsLocal,
  setPatientUpdateLoading,
  setSelectedPatientChangesDisabled,
) {
  const dataToSubmit = {
    ...patientDetailsLocal,
    apiParam: 'PatientInfo',
    id: patientId,
    clinicId: clinicId,
    clinicBrandId: clinicBrandId,
    mentorId: mentorProfileData?.id,
    name: patientDetailsLocal?.name,
    email: patientDetailsLocal?.email,
    mobile:
      patientDetailsLocal?.mobile && `+${removePlusFromBeginning(patientDetailsLocal?.mobile)}`,
    dateOfBirth: getDOBWithTime(patientDetailsLocal?.age),
    gender: patientDetailsLocal?.gender,
    note: patientDetailsLocal?.note,
    referredBy: patientDetailsLocal?.referredBy,
    location: {
      address: patientDetailsLocal?.address,
      pincode: patientDetailsLocal?.pincode,
      city: patientDetailsLocal?.city,
    },
  }
  setPatientUpdateLoading(true)
  let response = await postCPDataWithoutParams('/api/secure/patient/save', dataToSubmit)
  if (response.status === 200) {
    toast.success('Patient Details updated')
  } else {
    toast.error('Error creating new patient')
  }
  setPatientUpdateLoading(false)
  setSelectedPatientChangesDisabled(true)
}

function removeInvalidValuesCash(apiSubmissionBody) {
  const updatedCashBreakUp = {}

  for (const denomination in apiSubmissionBody.cashBreakUp) {
    const value = apiSubmissionBody.cashBreakUp[denomination]
    updatedCashBreakUp[denomination] = typeof value === 'number' && !isNaN(value) ? value : 0
  }

  return {
    ...apiSubmissionBody,
    cashBreakUp: updatedCashBreakUp,
  }
}

async function makeBookingAssistant(
  askPayment,
  professionalData,
  bookingSlotSelected,
  eventData,
  patientId,
  clinicId,
  actionItem,
  paymentDetail,
  setBookingLoading,
  refreshPendingActions,
) {
  let paymentGateway
  if (askPayment) {
    paymentGateway = 'paidOffline'
  } else {
    paymentGateway = 'free'
  }
  const clientTransactionId = uuidv4()
  const dataToSubmit = {
    apiParam: 'booked-event-info',
    mentorId: professionalData?.id,
    userId: '',
    startTime: moment
      ?.utc(bookingSlotSelected?.timeSlotItem?.startTime)
      ?.add(bookingSlotSelected?.preBufferMins, 'minutes')
      ?.format('YYYY-MM-DD HH:mm:ss'),
    endTime: moment
      ?.utc(bookingSlotSelected?.timeSlotItem?.endTime)
      ?.subtract(bookingSlotSelected?.postBufferMins, 'minutes')
      ?.format('YYYY-MM-DD HH:mm:ss'),
    eventType: eventData?.type,
    eventTypeId: eventData?.uid,
    subscriptionPlanId: eventData?.subscriptionPlanId,
    patientId: patientId,
    id: '',
    feePaid: askPayment ? paymentDetail?.feePaid : '',
    currency: paymentDetail?.currency || 'inr',
    feePaymentMode: askPayment ? paymentDetail?.feePaymentMode : '',
    feePaidTxnId: askPayment ? paymentDetail?.feePaidTxnId : '',
    clinicId: clinicId || '',
  }
  setBookingLoading(true)
  let response
  // distinction between clinic assistant api and mentor api
  response = await putCPDataWithoutParams(
    `/api/secure/meeting/book?teacherId=${
      professionalData?.id
    }&paymentGateway=${paymentGateway}&clientTransactionId=${clientTransactionId}&platform=${platformWeb}&paymentMethod=upi&actionQId=${
      actionItem?.id
    }&cancelUrl=${encodeURIComponent(window.location.href)}&successUrl=${encodeURIComponent(
      window.location.href,
    )}`,
    dataToSubmit,
  )
  setBookingLoading(false)
  if (response?.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
    toast.success('Booked session')
    refreshPendingActions()
  } else {
    toast.error('Error Booking Session')
  }
}

function getOverlapDataWithPreviousSlots(bookedSlotItem, bookedSlotsPerUtc) {
  // 1 part : RN only checking if there are any slots before this booked slot,
  // having same starttime and endtime as this slot, and then using adding duration in this slot
  // 2 part : the other thing is shifting of other slots, very basic case, we can shift all slots that are below instead of checking if they need to be shifted, doing this
  // 3 part: there will be slots that will need to be shifted as they are not same starttime, endtime, but there has already been an overlap and downshift above. so we will need to push these too
  // possible bug: exact same second bookings might not show up

  const allBookingsBeforeThisBooking = bookedSlotsPerUtc?.filter((item) =>
    moment(item?.createdTime)?.isBefore(bookedSlotItem?.createdTime),
  )

  const sameTimeOverlapsAsThisBooking = allBookingsBeforeThisBooking?.filter(
    (item) =>
      item?.startTime === bookedSlotItem?.startTime && item?.endTime === bookedSlotItem?.endTime,
  )?.length

  let minutesToAddToThisEvent = 0

  const countMap = new Map() // Map to store counts of items with same start and end times and Iterate through the array
  allBookingsBeforeThisBooking?.forEach((item) => {
    const key = `${item.startTime}_${item.endTime}` // Create a unique key using start and end times
    if (countMap.has(key)) {
      // If the key already exists, increment the count
      countMap.set(key, countMap.get(key) + 1)
    } else {
      // If the key doesn't exist, initialize the count to 1
      countMap.set(key, 1)
    }
  })
  countMap.forEach((value, key) => {
    let additionAmount = 0
    if (`${bookedSlotItem.startTime}_${bookedSlotItem.endTime}`) {
    } else {
      if (value > 1) {
        additionAmount = value - 1
      }
      minutesToAddToThisEvent += additionAmount * bookedSlotItem?.eventDuration
    }
  })

  if (sameTimeOverlapsAsThisBooking > 0) {
    minutesToAddToThisEvent += sameTimeOverlapsAsThisBooking * bookedSlotItem?.eventDuration
  }
  return { minutesToAddToThisEvent: minutesToAddToThisEvent }
}

const customSortForDayCalendar = (a, b) => {
  // Check if both items have 'createdTime'
  if (a?.createdTime && b?.createdTime) {
    return moment(a?.createdTime).diff(moment(b?.createdTime))
  }
  // Check if both items have 'modifiedTime'
  else if (a?.modifiedTime && b?.modifiedTime) {
    return moment(a?.modifiedTime).diff(moment(b?.modifiedTime))
  }
  // If 'createdTime' or 'modifiedTime' is missing, sort based on 'appointmentNumber'
  else {
    return a?.appointmentNumber - b?.appointmentNumber
  }
}

const BookingRequestStrip = ({ bookingRequestId, cancelClickFunction, bookingRequestData }) => {
  return (
    <BookingRequestContainer>
      {/* looking status loader */}
      {bookingRequestData?.status === BOOKING_REQUEST_STATUS.BOOKING_INITIATED && (
        <div style={{ display: 'flex', gap: '8px' }}>
          <div>Looking for therapists in {bookingRequestData?.clinicLocationName}</div>
          <div className="loader"></div>
        </div>
      )}

      {/* patient details */}
      <div>
        Patient Details:{' '}
        <b>
          {bookingRequestData?.patientName || bookingRequestData?.patientId},{' '}
          {
            GENDER_LIST_OFFLINE?.find((item) => item?.value === bookingRequestData?.patientGender)
              ?.label
          }
          ,{' '}
          {moment()?.diff(
            moment.utc(bookingRequestData?.patientDateOfBirth, 'YYYY-MM-DD HH:mm:ss'),
            'years',
          )}
          {Array.isArray(bookingRequestData?.patientLanguages) &&
            bookingRequestData?.patientLanguages?.length > 0 &&
            `, speaks ${bookingRequestData?.patientLanguages?.join(', ')}`}
        </b>
      </div>

      {/* mentor */}
      <div>Suggested by {bookingRequestData?.suggestedByMentorName}</div>

      {/* started looking */}
      <div>
        Started Looking:{' '}
        {moment
          .utc(bookingRequestData?.createdTime, 'YYYY-MM-DD HH:mm:ss')
          ?.local()
          ?.format('DD MMM, h:mma')}
      </div>

      {/* session start time */}
      <div>
        Session Starts:{' '}
        {moment
          .utc(bookingRequestData?.startTime, 'YYYY-MM-DD HH:mm:ss')
          ?.local()
          ?.format('DD MMM, h:mma')}
      </div>

      {/* finalised fee data */}
      {bookingRequestData?.fee && bookingRequestData?.currency && (
        <div>
          Collect Fee:{' '}
          {(
            currency_list?.find((item) => item?.code === bookingRequestData?.currency)?.code || ''
          ).toUpperCase()}{' '}
          {bookingRequestData?.fee}
        </div>
      )}

      {/* status and action buttons */}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div></div>
        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          {bookingRequestData?.status === BOOKING_REQUEST_STATUS.BOOKING_INITIATED && (
            <button
              style={{
                background: 'red',
                color: '#fff',
                padding: '4px 8px',
                borderRadius: '4px',
              }}
              onClick={() => cancelClickFunction(bookingRequestId)}
            >
              Cancel
            </button>
          )}

          {bookingRequestData?.status === BOOKING_REQUEST_STATUS.BOOKING_CANCELLED && (
            <div style={{ fontWeight: 600, color: 'red' }}>Cancelled</div>
          )}

          {bookingRequestData?.status === BOOKING_REQUEST_STATUS.BOOKING_EXPIRED && (
            <div style={{ fontWeight: 600, color: 'red' }}>Expired</div>
          )}

          {bookingRequestData?.status === BOOKING_REQUEST_STATUS.BOOKING_ACCEPTED && (
            <div style={{ fontWeight: 600, color: 'green' }}>
              Accepted by {bookingRequestData?.acceptedByName}
            </div>
          )}
          {bookingRequestData?.status === BOOKING_REQUEST_STATUS.BOOKING_BOOKED && (
            <div className="d-flex flex-column align-items-end">
              <div style={{ fontWeight: 600, color: 'green' }}>
                Booking created with {bookingRequestData?.acceptedByName}
              </div>
              <div>
                ({platformOptions.find((item) => item.id === bookingRequestData?.mode)?.name})
              </div>
            </div>
          )}
        </div>
      </div>
    </BookingRequestContainer>
  )
}

export const AllPastBookingRequestListSection = ({
  bookingRequestsData,
  clinicLocationData,
  bookingRequestsDataLoading,
  setFetchDataTriggerForBookingRequests,
}) => {
  const [dataLoading, setDataLoading] = useState(false)

  async function cancelBookingRequest(bookingRequestId) {
    setDataLoading(true)
    await cancelBookingRequestFuncForAssistant(bookingRequestId)
    setDataLoading(false)
    setFetchDataTriggerForBookingRequests((prev) => !prev)
  }

  const tooltipComp = (
    <Tooltip id="tooltip">
      <strong>Update Therapy Booking Requests</strong>
    </Tooltip>
  )

  useEffect(() => {
    const interval = setInterval(() => {
      setFetchDataTriggerForBookingRequests((prev) => !prev)
    }, 60000) // 1 minute

    // setFetchDataTriggerForBookingRequests((prev) => !prev) // already called outside - Call initially

    return () => clearInterval(interval) // Cleanup function to clear the interval
  }, [])

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '32px' }}>
        <div>
          <div>
            <b>All Therapy Booking Requests</b>
          </div>
          <div style={{ fontSize: '12px' }}>
            (where start time of session is after{' '}
            {moment()?.subtract(24, 'hours')?.format('DD-MM-YYYY h:mm A')})
          </div>
        </div>
        {dataLoading ? (
          <Spinner animation="border" variant="dark" size="sm" />
        ) : (
          <OverlayTrigger placement="bottom" overlay={tooltipComp}>
            <StyledEventsRefreshIcon
              onClick={() => setFetchDataTriggerForBookingRequests((prev) => !prev)}
            />
          </OverlayTrigger>
        )}
      </div>
      <BookingRequestItemsParent>
        {(bookingRequestsDataLoading || dataLoading) && (
          <GenericLoadingOverlay
            backgroundColor={'rgba(78, 8, 122, 0.15)'}
            spinnerVariant={'dark'}
          />
        )}
        {bookingRequestsData?.map((item, index) => {
          return (
            <BookingRequestStrip
              key={index}
              bookingRequestId={item?.id}
              cancelClickFunction={cancelBookingRequest}
              bookingRequestData={item}
            />
          )
        })}
      </BookingRequestItemsParent>
    </div>
  )
}

const CustomNoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      Click on Add New Button to add new patient
    </components.NoOptionsMessage>
  )
}

const CustomPlaceholder = (props) => (
  <components.Placeholder {...props}>
    <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
      <SearchIcon style={{ fontSize: '16px' }} />
      <span style={{ display: 'flex', alignItems: 'center' }}>Search Patient</span>
    </div>
  </components.Placeholder>
)

const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      <div>
        {props?.label}
        {props?.data?.primaryPatientProfile && (
          <>
            {' '}
            <span
              style={{
                background: '#FE8D59',
                padding: '2px 4px',
                borderRadius: '16px',
                border: '1px solid #000',
                fontSize: '12px',
              }}
            >
              Relative
            </span>
          </>
        )}
      </div>
    </components.Option>
  )
}

export function shouldShowTwoRowDesign(arr) {
  try {
    if (
      arr.some(
        (item) =>
          item?.previousSteps && Array.isArray(item.previousSteps) && item.previousSteps.length > 0,
      ) ||
      arr.some(
        (item) => item?.nextSteps && Array.isArray(item.nextSteps) && item.nextSteps.length > 0,
      )
    ) {
      return true
    }
    return false
  } catch (err) {
    return false
  }
}

const AvailableScreenerComponent = ({ eventBeingBooked, setScreenerAvailable }) => {
  const [loading, setLoading] = useState(false)
  const [possibleSession, setPossibleSession] = useState()

  async function checkPossibleScreeningSessionForThisBooking() {
    setLoading(true)
    const response = await putCPDataWithoutParams(
      `/api/secure/meeting/screening/find-possible`,
      eventBeingBooked,
    )
    setLoading(false)
    if (
      response?.status === 200 &&
      isCommonResponseSuccessful(response?.data?.code) &&
      response?.data?.data?.screening
    ) {
      setPossibleSession(response?.data?.data?.screening)
      setScreenerAvailable(true)
    } else {
      setScreenerAvailable(false)
      setPossibleSession()
    }
    console.log(response?.data?.data?.screening)
  }

  useEffect(() => {
    checkPossibleScreeningSessionForThisBooking()
  }, [])

  return (
    <div>
      {loading ? (
        <Spinner animation="border" variant="dark" size="sm" />
      ) : (
        <div>
          (
          {possibleSession?.mentorName
            ? `Available: ${possibleSession?.mentorName}`
            : 'No Screener Available right now'}
          )
        </div>
      )}
    </div>
  )
}
