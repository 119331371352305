import { toast } from 'react-toastify'
import { isCommonResponseSuccessful } from 'src/mentor/helpers/utilityFunctions'
import { getMPDataWithoutParams } from 'src/mentor/services'

export function getSectionsAndQuestionsBasedOnFilledAnswers({ sections, answers }) {
  const validAnswers = answers?.filter((answer) => !!answer.response)
  const sectionsWithValidQuestions = sections?.map((section) => {
    const validQuestions = section?.questions?.filter((question) =>
      validAnswers?.some((answer) => answer?.questionId === question.id),
    )
    return {
      ...section,
      questions: validQuestions,
    }
  })
  return sectionsWithValidQuestions
    ?.filter((section) => section?.questions?.length > 0)
    ?.map((section) => {
      return {
        ...section,
        questions: section?.questions?.map((question) => {
          const answer = validAnswers?.find((answer) => answer?.questionId === question?.id)
          return {
            ...question,
            answer,
          }
        }),
      }
    })
}

export async function getScreeningSectionsFromDb({ doctorSpecializations, visitType }) {
  const specializations = (doctorSpecializations || []).join(',')

  const response = await getMPDataWithoutParams(
    `/api/secure/screener/screening-section/sections?specializations=${specializations}&visit-type=${visitType}`,
  )

  if (response.status === 200 && isCommonResponseSuccessful(response.data.code)) {
    const screeningSections = response?.data?.data?.['screening-section']
    return filterOutSectionsWithoutQuestions(screeningSections || [])
  } else {
    toast.error('Error getting screening report data')
    return []
  }
}

function filterOutSectionsWithoutQuestions(sections) {
  return sections?.filter((section) => section?.questions?.length > 0)
}
