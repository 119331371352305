import moment from 'moment'
import { allAvailableEventTypes } from 'src/consumer/constants'

export function getAvailabilityForDayCalendar(
  selectedDate,
  timeZone,
  scheduleForSelectedEventType,
) {
  /////

  const neededSlotsInRangePerUserTZ = {
    visibleDate: selectedDate,
    dayStart: moment(selectedDate, 'DD-MM-YYYY')?.format(
      `YYYY-MM-DDTHH:mm:ss[${decodeURIComponent(timeZone)}]`,
    ),
    dayEnd: moment(selectedDate, 'DD-MM-YYYY')
      ?.endOf('day')
      ?.format(`YYYY-MM-DDTHH:mm:ss[${decodeURIComponent(timeZone)}]`),
  }

  const neededSlotsInRangePerUTC = {
    visibleDate: neededSlotsInRangePerUserTZ.visibleDate,
    dayStart: moment?.utc(neededSlotsInRangePerUserTZ.dayStart)?.format(`YYYY-MM-DDTHH:mm:ss[Z]`),
    dayEnd: moment?.utc(neededSlotsInRangePerUserTZ.dayEnd)?.format(`YYYY-MM-DDTHH:mm:ss[Z]`),
  }

  const neededSlotsInRangePerMentorTZ = {
    visibleDate: neededSlotsInRangePerUTC.visibleDate,
    dayStart: moment
      ?.utc(neededSlotsInRangePerUTC.dayStart)
      ?.utcOffset(decodeURIComponent(scheduleForSelectedEventType?.timeZone))
      ?.format(
        `YYYY-MM-DDTHH:mm:ss[${decodeURIComponent(scheduleForSelectedEventType?.timeZone)}]`,
      ),
    dayEnd: moment
      ?.utc(neededSlotsInRangePerUTC.dayEnd)
      ?.utcOffset(decodeURIComponent(scheduleForSelectedEventType?.timeZone))
      ?.format(
        `YYYY-MM-DDTHH:mm:ss[${decodeURIComponent(scheduleForSelectedEventType?.timeZone)}]`,
      ),
  }

  /////

  const selectedDateAvailabilityPerMentor = []
  if (
    neededSlotsInRangePerMentorTZ?.dayStart?.substring(0, 10) ===
    neededSlotsInRangePerMentorTZ?.dayEnd?.substring(0, 10)
  ) {
    const mentorDayData =
      scheduleForSelectedEventType?.weeklyHourMap?.[
        moment(neededSlotsInRangePerMentorTZ?.dayStart?.substring(0, 10))
          ?.format('dddd')
          ?.toLowerCase()
      ]
    if (mentorDayData && mentorDayData?.available) {
      mentorDayData?.slotList?.forEach((slotItem) => {
        selectedDateAvailabilityPerMentor?.push({
          startTime:
            neededSlotsInRangePerMentorTZ?.dayStart?.substring(0, 10) +
            'T' +
            slotItem?.startTime +
            decodeURIComponent(scheduleForSelectedEventType?.timeZone),
          endTime:
            neededSlotsInRangePerMentorTZ?.dayStart?.substring(0, 10) +
            'T' +
            slotItem?.endTime +
            decodeURIComponent(scheduleForSelectedEventType?.timeZone),
        })
      })
    }
  } else {
    const mentorStartDayData =
      scheduleForSelectedEventType?.weeklyHourMap?.[
        moment(neededSlotsInRangePerMentorTZ?.dayStart?.substring(0, 10))
          ?.format('dddd')
          ?.toLowerCase()
      ]
    const mentorEndDayData =
      scheduleForSelectedEventType?.weeklyHourMap?.[
        moment(neededSlotsInRangePerMentorTZ?.dayEnd?.substring(0, 10))
          ?.format('dddd')
          ?.toLowerCase()
      ]

    if (mentorStartDayData?.available) {
      mentorStartDayData?.slotList?.forEach((startDayItem) => {
        if (
          startDayItem?.startTime > neededSlotsInRangePerMentorTZ?.dayStart?.substring(11, 19) &&
          startDayItem?.endTime > neededSlotsInRangePerMentorTZ?.dayStart?.substring(11, 19)
        ) {
          selectedDateAvailabilityPerMentor?.push({
            startTime:
              neededSlotsInRangePerMentorTZ?.dayStart?.substring(0, 10) +
              'T' +
              startDayItem?.startTime +
              neededSlotsInRangePerMentorTZ?.dayStart?.substring(19),
            endTime:
              neededSlotsInRangePerMentorTZ?.dayStart?.substring(0, 10) +
              'T' +
              startDayItem?.endTime +
              neededSlotsInRangePerMentorTZ?.dayStart?.substring(19),
          })
        }
        if (
          startDayItem?.startTime < neededSlotsInRangePerMentorTZ?.dayStart?.substring(11, 19) &&
          startDayItem?.endTime > neededSlotsInRangePerMentorTZ?.dayStart?.substring(11, 19)
        ) {
          selectedDateAvailabilityPerMentor?.push({
            startTime:
              neededSlotsInRangePerMentorTZ?.dayStart?.substring(0, 10) +
              'T' +
              neededSlotsInRangePerMentorTZ?.dayStart?.substring(11, 19) +
              neededSlotsInRangePerMentorTZ?.dayStart?.substring(19),
            endTime:
              neededSlotsInRangePerMentorTZ?.dayStart?.substring(0, 10) +
              'T' +
              startDayItem?.endTime +
              neededSlotsInRangePerMentorTZ?.dayStart?.substring(19),
          })
        }
      })
    }

    if (mentorEndDayData?.available) {
      mentorEndDayData?.slotList?.forEach((endDayItem) => {
        if (
          endDayItem?.startTime < neededSlotsInRangePerMentorTZ?.dayEnd?.substring(11, 19) &&
          endDayItem?.endTime < neededSlotsInRangePerMentorTZ?.dayEnd?.substring(11, 19)
        ) {
          selectedDateAvailabilityPerMentor?.push({
            startTime:
              neededSlotsInRangePerMentorTZ?.dayEnd?.substring(0, 10) +
              'T' +
              endDayItem?.startTime +
              neededSlotsInRangePerMentorTZ?.dayEnd?.substring(19),
            endTime:
              neededSlotsInRangePerMentorTZ?.dayEnd?.substring(0, 10) +
              'T' +
              endDayItem?.endTime +
              neededSlotsInRangePerMentorTZ?.dayEnd?.substring(19),
          })
        }
        if (
          endDayItem?.startTime < neededSlotsInRangePerMentorTZ?.dayEnd?.substring(11, 19) &&
          endDayItem?.endTime > neededSlotsInRangePerMentorTZ?.dayEnd?.substring(11, 19)
        ) {
          selectedDateAvailabilityPerMentor?.push({
            startTime:
              neededSlotsInRangePerMentorTZ?.dayEnd?.substring(0, 10) +
              'T' +
              endDayItem?.startTime +
              neededSlotsInRangePerMentorTZ?.dayEnd?.substring(19),
            endTime:
              neededSlotsInRangePerMentorTZ?.dayEnd?.substring(0, 10) +
              'T' +
              neededSlotsInRangePerMentorTZ?.dayEnd?.substring(11, 19) +
              neededSlotsInRangePerMentorTZ?.dayEnd?.substring(19),
          })
        }
      })
    }
  }

  const selectedDateAvailabilityPerUTC = []
  selectedDateAvailabilityPerMentor?.forEach((slotItem) => {
    selectedDateAvailabilityPerUTC?.push({
      startTime: moment?.utc(slotItem?.startTime)?.format('YYYY-MM-DDTHH:mm:ss[Z]'),
      endTime: moment?.utc(slotItem?.endTime)?.format('YYYY-MM-DDTHH:mm:ss[Z]'),
    })
  })

  const selectedDateAvailabilityPerUser = []
  selectedDateAvailabilityPerUTC?.forEach((slotItem) => {
    selectedDateAvailabilityPerUser?.push({
      startTime: moment
        ?.utc(slotItem?.startTime)
        ?.utcOffset(decodeURIComponent(timeZone))
        ?.format(`YYYY-MM-DDTHH:mm:ss[${decodeURIComponent(timeZone)}]`),
      endTime: moment
        ?.utc(slotItem?.endTime)
        ?.utcOffset(decodeURIComponent(timeZone))
        ?.format(`YYYY-MM-DDTHH:mm:ss[${decodeURIComponent(timeZone)}]`),
    })
  })

  return selectedDateAvailabilityPerUser
}

export function getSlotGroupsForDayCalendar(
  availabilityOfDay,
  gapDurationInMins,
  timeZone,
  bookedSlotsInDay,
) {
  const slotGroupArray = []
  sortArrayMomentBased(availabilityOfDay, 'startTime', true)

  availabilityOfDay?.forEach((availableTimeBlock) => {
    const slotsOfThisTimeBlock = []
    let currentStartTime = moment(availableTimeBlock?.startTime)
    let currentSlotEnd = moment(currentStartTime)?.add(gapDurationInMins, 'minutes')
    let endTime = moment(availableTimeBlock?.endTime)

    while (currentStartTime?.isSameOrBefore(endTime) && currentSlotEnd?.isSameOrBefore(endTime)) {
      const currentStartTimeFormatted = currentStartTime?.format(
        `YYYY-MM-DDTHH:mm:ss[${decodeURIComponent(timeZone)}]`,
      )
      const currentSlotEndFormatted = currentSlotEnd?.format(
        `YYYY-MM-DDTHH:mm:ss[${decodeURIComponent(timeZone)}]`,
      )
      slotsOfThisTimeBlock?.push({
        startTime: currentStartTimeFormatted,
        endTime: currentSlotEndFormatted,
      })
      currentStartTime?.add(gapDurationInMins, 'minutes')
      currentSlotEnd?.add(gapDurationInMins, 'minutes')
    }
    if (slotsOfThisTimeBlock?.length > 0) {
      slotGroupArray?.push(slotsOfThisTimeBlock)
    }
  })

  const referenceDay = slotGroupArray?.[0]?.[0]?.startTime

  slotGroupArray?.forEach((slotGroup, slotGroupIndex) => {
    const modifiedSlotGroup = [...slotGroup]
    let count = 1
    while (checkIfLastSlotHasOverlap(modifiedSlotGroup, bookedSlotsInDay)) {
      const proposedAddition = {
        startTime: moment(modifiedSlotGroup?.[modifiedSlotGroup?.length - 1]?.startTime)
          .add(gapDurationInMins, 'minutes')
          .format('YYYY-MM-DDTHH:mm:ssZZ'),
        endTime: moment(modifiedSlotGroup?.[modifiedSlotGroup?.length - 1]?.endTime)
          .add(gapDurationInMins, 'minutes')
          .format('YYYY-MM-DDTHH:mm:ssZZ'),
      }
      if (
        checkIfProposedSlotAllowed(
          proposedAddition?.endTime,
          slotGroupArray?.[slotGroupIndex + 1],
          referenceDay,
        )
      ) {
        modifiedSlotGroup.push(proposedAddition)
      } else {
        break
      }
      slotGroupArray[slotGroupIndex] = modifiedSlotGroup
    }
  })
  return slotGroupArray
}

export function getSlotGroupsForChamberRoomDayCalendar(
  availabilityOfDay,
  gapDurationInMins,
  timeZone,
) {
  const slotGroupArray = []
  sortArrayMomentBased(availabilityOfDay, 'startTime', true)

  availabilityOfDay?.forEach((availableTimeBlock) => {
    const slotsOfThisTimeBlock = []
    let currentStartTime = moment(availableTimeBlock?.startTime)
    let currentSlotEnd = moment(currentStartTime)?.add(gapDurationInMins, 'minutes')
    let endTime = moment(availableTimeBlock?.endTime)

    while (currentStartTime?.isSameOrBefore(endTime) && currentSlotEnd?.isSameOrBefore(endTime)) {
      const currentStartTimeFormatted = currentStartTime?.format(
        `YYYY-MM-DDTHH:mm:ss[${decodeURIComponent(timeZone)}]`,
      )
      const currentSlotEndFormatted = currentSlotEnd?.format(
        `YYYY-MM-DDTHH:mm:ss[${decodeURIComponent(timeZone)}]`,
      )
      slotsOfThisTimeBlock?.push({
        startTime: currentStartTimeFormatted,
        endTime: currentSlotEndFormatted,
      })
      currentStartTime?.add(gapDurationInMins, 'minutes')
      currentSlotEnd?.add(gapDurationInMins, 'minutes')
    }
    if (slotsOfThisTimeBlock?.length > 0) {
      slotGroupArray?.push(slotsOfThisTimeBlock)
    }
  })

  return slotGroupArray
}

function checkIfLastSlotHasOverlap(slotGroup, bookedSlotsInDay) {
  let toReturn = false

  if (slotGroup?.length > 0) {
    const lastSlotOfGroup = slotGroup?.[slotGroup?.length - 1]
    bookedSlotsInDay?.forEach((bookedSlot) => {
      if (
        moment(bookedSlot?.startTimeForDayCal).isBetween(
          moment(lastSlotOfGroup.startTime),
          moment(lastSlotOfGroup?.endTime),
          null,
          '[]',
        ) ||
        moment(bookedSlot?.endTimeForDayCal).isBetween(
          moment(lastSlotOfGroup.startTime),
          moment(lastSlotOfGroup?.endTime),
          null,
          '[]',
        )
      ) {
        if (!toReturn) {
          toReturn = true
        }
      } else {
      }
    })
  } else {
  }

  return toReturn
}

function checkIfProposedSlotAllowed(endTimeOfProposedSlot, nextSlotGroup, referenceDay) {
  let toReturn = true
  if (referenceDay && endTimeOfProposedSlot) {
    if (
      !moment(referenceDay, 'YYYY-MM-DDTHH:mm:ssZZ').isSame(
        moment(endTimeOfProposedSlot, 'YYYY-MM-DDTHH:mm:ssZZ'),
        'day',
      )
    ) {
      console.log('not adding slot as new slot will be on new day')
      return false
    }
  }
  if (nextSlotGroup && endTimeOfProposedSlot) {
    if (
      moment(endTimeOfProposedSlot, 'YYYY-MM-DDTHH:mm:ssZZ').isSameOrAfter(
        moment(nextSlotGroup?.[0]?.startTime, 'YYYY-MM-DDTHH:mm:ssZZ'),
      )
    ) {
      console.log('not adding slot as new slot will enter next slotgroup')
      return false
    }
  }
  return toReturn
}

export function sortArrayMomentBased(inputArray, fieldToCompare, ascending = true) {
  if (Array.isArray(inputArray)) {
    const compareMoments = (a, b) => {
      const momentA = a && a?.[fieldToCompare] ? moment(a[fieldToCompare]) : moment()
      const momentB = b && b?.[fieldToCompare] ? moment(b[fieldToCompare]) : moment()
      if (momentA.isSame(momentB)) {
        return 0
      }
      if (ascending) {
        if (momentA.isBefore(momentB)) {
          return -1
        } else {
          return 1
        }
      } else {
        if (momentB.isBefore(momentA)) {
          return -1
        } else {
          return 1
        }
      }
    }

    return inputArray.sort(compareMoments)
  } else {
    return []
  }
}

export function getCorrectScreeningReasonMessageObject({
  eventType,
  isTryingToBookScreening,
  isScreenerAvailable,
  reasonIdForNotDoingScreening,
  reasonForNotDoingScreening,
}) {
  if (eventType === allAvailableEventTypes?.consultation) {
    if (isTryingToBookScreening) {
      if (!isScreenerAvailable) {
        return {
          id: 'screener_not_available',
          title: 'Screener not available',
        }
      }
      return null
    } else {
      return {
        id: reasonIdForNotDoingScreening,
        title: reasonForNotDoingScreening,
      }
    }
  }
  return null
}
